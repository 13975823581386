import { useState } from "react";
import { auth, isHabitualEmail } from "../helpers/firebaseHelper";
import { useAuthState } from "react-firebase-hooks/auth";
import "./globals.css";
import { Sidebar } from "./sidebar";
import { ManagePatients } from "./manage-patients";
import { PrescriptionPayments } from "./prescription-payments";
export const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;

export const Dashboard = ({ paymentsView }: { paymentsView?: boolean }) => {
  const [loading, setLoading] = useState(false);
  const [user] = useAuthState(auth);
  if (!user || !user.email) {
    console.log('dashboard would be going home')
    // navigate("/");
    return null;
  } else {
    const query =
      window.location.href.split("?query=").length > 1
        ? window.location.href.split("?query=")[1]
        : undefined;
    return (
      <div className={"dash"}>
        <Sidebar
          activeTab={paymentsView ? "prescriptionPayments" : "dashboard"}
        />
        <div className={"screen"}>
          {loading && (
            <div className="loadingOverlay">
              <div>Loading...</div>
            </div>
          )}
          {paymentsView ? (
            <PrescriptionPayments
              setLoading={(isLoading: boolean) => setLoading(isLoading)}
              initialEmail={query}
            />
          ) : (
            <ManagePatients
              setLoading={(isLoading: boolean) => setLoading(isLoading)}
              initialEmail={query}
              isHabitualAdmin={isHabitualEmail(user.email)}
            />
          )}
        </div>
      </div>
    );
  }
};
