import React, { Fragment } from "react";
import "./globals.css";

export const SearchList = ({
  title,
  items,
}: {
  title: string;
  items: { content: any[]; onClick: () => void }[] | null;
}) => {
  return (
    <div>
      <h4>{title}</h4>
      <ul>
        {items?.map((item, index) => (
          <Fragment key={title + index}>
            <li>
              <button onClick={item.onClick}>
                <div>
                  {item.content.map((content, itemIndex) => (
                    <Fragment key={title + "-item-" + itemIndex}>
                      <span>{content}</span>
                      {itemIndex !== item.content.length - 1 && " - "}
                    </Fragment>
                  ))}
                </div>
              </button>
            </li>
          </Fragment>
        ))}
      </ul>
    </div>
  );
};
