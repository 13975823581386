import axios from "axios";
import Chevron from "../images/chevron-dark.svg";
import { useCallback, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import { apiEndpoint } from "./manage-patients";
import { format } from "date-fns";
import { InitialConsultCompareModal } from "./initial-consult-compare-modal";

export type InitialConsultationAnswer = {
  id: string;
  answer: string;
  question: string;
  data: {};
  questionId: string;
};

export type InitialConsultationAnswers = {
  [id: string]: InitialConsultationAnswer;
};

export type InitialConsultationType =
  | "ID_CHECK"
  | "BASIC_ELIGIBILITY"
  | "FULL_CONSULTATION"
  | "SUBMITTED";

export type InitialConsultation = {
  InitialConsultationAnswers: InitialConsultationAnswer[];
  type?: InitialConsultationType;
  createdAt?: string;
  id: number;
  eligible?: boolean;
  bmi?: number;
  weightKg?: number;
  heightCm?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  sex?: any;
  newDosage?: string;
  PatientId?: number;
  consultationId?: string;
  prescriptionPayment?: any;
};

const InitialConsultationListItem = ({
  checked,
  item,
  onChange,
  key,
}: {
  checked: boolean;
  item: InitialConsultation;
  onChange: () => void;
  key: any;
}) => {
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  return (
    <div>
      <div
        key={key}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flex: 1,
        }}
      >
        <button
          onClick={() => {
            setShowMoreInfo(!showMoreInfo);
          }}
        >
          <img
            src={Chevron}
            alt="chevron"
            style={{
              height: 15,
              width: 15,
              marginRight: 10,
              transform: showMoreInfo ? "rotate(90deg)" : undefined,
            }}
          />
        </button>
        <h4>
          Initial Consultation -{" "}
          {item?.createdAt && format(new Date(item?.createdAt), "yyyy-MM-dd")}
        </h4>
        <input
          onChange={(e) => {
            onChange();
          }}
          checked={checked}
          type="checkbox"
          style={{ marginLeft: 10 }}
        />
      </div>
      <div style={{ paddingLeft: 25 }}>
        <div>BMI - {item?.bmi?.toFixed(2)}</div>
        <div>New doseage - {item?.newDosage}</div>
      </div>
      {showMoreInfo && (
        <div style={{ paddingLeft: 25, marginTop: 20 }}>
          {Object.values(item?.InitialConsultationAnswers)?.map(
            (reviewAnswer, key) => {
              return (
                <div key={key} style={{ marginBottom: 10 }}>
                  - {reviewAnswer.question}:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {reviewAnswer?.answer}
                  </span>
                </div>
              );
            }
          )}
        </div>
      )}
    </div>
  );
};

export const InitialConsultationResponses = ({ data }: any) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [responses, setResponses] = useState<{
    [key: number]: InitialConsultation;
  }>({});
  const [responsesToCompare, setResponsesToCompare] = useState<{
    [key: number]: InitialConsultation;
  }>([]);
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);

  useEffect(() => {
    if (authToken) {
      axios({
        method: "get",
        url: `${apiEndpoint}admin/patient/${data.email}/initialConsultationHistory`,
        headers: {
          "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
          Authorization: `Bearer ${authToken}`,
          "x-api-key": `${process.env.REACT_APP_APP_API_KEY}`,
        },
      }).then((response) => {
        if (response && response.data) {
          setResponses(response.data);
        }
      });
    }
  }, [authToken, data.email]);

  return (
    <div>
      <div
        style={{
          alignItems: "flex-end",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button
          onClick={() => {
            setShowModal(true);
          }}
          disabled={Object.keys(responsesToCompare).length < 2}
          style={{ display: "flex" }}
          className="cta"
        >
          Compare
        </button>
      </div>
      {Object.entries(responses).map(([itemKey, item], key) => {
        return (
          <InitialConsultationListItem
            key={key}
            item={item}
            checked={!!responsesToCompare[item.id]}
            onChange={() => {
              if (responsesToCompare[item.id]) {
                // already in there so remove it
                const { [item.id]: _, ...newObj } = responsesToCompare;
                setResponsesToCompare({ ...newObj });
              } else {
                // not in there so add it
                setResponsesToCompare({
                  ...responsesToCompare,
                  [item.id]: { ...item },
                });
              }
            }}
          />
        );
      })}
      {showModal && (
        <InitialConsultCompareModal
          dismissModal={() => {
            setShowModal(false);
          }}
          data={responsesToCompare}
        />
      )}
    </div>
  );
};
