import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./globals.css";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import logger from "../helpers/logger";
import promiseAccumulator from "../helpers/promiseAccumulator";
import { FIRST_DOSE_TAKEN_TASK_ID, FULL_BODY_SELFIE_TASK_ID, FullBodySelfieStatus, ID_VERIFICATION_TASK_ID, IdVerificationStatus } from "../types/types";
import Toggle from "react-toggle";
import "react-toggle/style.css";

export const ChecklistTasks = ({
  data,
  loading,
  refresh,
}: {
  data: { [key: string]: any } | null;
  loading: (state: boolean) => void;
  refresh: () => void;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);
  const [personaInquiryId, setPersonaInquiryId] = useState("");
  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
  const personaIdIsValid = useMemo(() => {
    return (
      personaInquiryId.length > 20 &&
      personaInquiryId.substring(0, 3).toLowerCase() === "inq"
    );
  }, [personaInquiryId]);
  const showPersonaIdError = useMemo(() => {
    return !personaIdIsValid && personaInquiryId.length >= 20;
  }, [personaIdIsValid, personaInquiryId.length]);

  const gettingReadyTasks = useMemo(() => {
    return data?.tasks?.find((task: { category: string }) => {
      return task?.category === "GETTING_READY";
    });
  }, [data?.tasks]);
  // const [arrayOfTasksToMarkIncomplete, setArrayOfTasksToMarkIncomplete] =
  //   useState<string[]>([]);


    const toggleTask = async (
      taskId: string,
      isComplete: boolean,
    ) => {
      try {
        loading(true);
        const url = `${apiEndpoint}admin/patient/${data?.email}/tasks/${taskId}`
        console.log('calling ', url)
        const response1 = await axios({
          method: "patch",
          url,
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
          },
          data: {
            isComplete
          },
        })
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: `Patient tasks was toggled - ${taskId}, complete ${isComplete}`,
          });
        }
        loading(false);
        refresh();
      } catch (error) {
        loading(false);
        alert("Something went wrong toggling task!");
        console.log(error);
      }
    };

  // const handleMarkTasksIncomplete = () => {
  //   const confirm = window.confirm(
  //     "Are you sure you want to delete these Getting Ready Checklist tasks?"
  //   );

  //   const requests = [
  //     () =>
  //       axios({
  //         method: "delete",
  //         url: `${apiEndpoint}admin/patient/${data?.email}/tasks`,
  //         data: { taskIds: arrayOfTasksToMarkIncomplete },
  //         headers: {
  //           "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
  //           Authorization: `Bearer ${authToken}`,
  //           "Content-Type": "application/json",
  //         },
  //       }),
  //   ];
  //   confirm &&
  //     promiseAccumulator(requests)
  //       .then(([response1]: any) => {
  //         loading(false);
  //         logger.info("Tasks were deleted:", response1);
  //         alert("Selected tasks deleted");
  //         return [response1];
  //       })
  //       .then(([response1]) => {
  //         if (response1.statusText === "OK") {
  //           updatePatientNotes({
  //             user,
  //             authToken,
  //             data,
  //             note: `Patient tasks were deleted - ${arrayOfTasksToMarkIncomplete}`,
  //           });
  //         }
  //       })
  //       .then(() => {
  //         refresh();
  //       })
  //       .catch((error) => {
  //         logger.error(error);
  //       });
  // };

  const handleManualApproval = (email: string) => {
    const confirm = window.confirm(
      "Are you sure you want to manually approve this user?"
    );

    const requests = [
      () =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/patient/${email}/idVerificationTask`,
          data: { inquiryId: personaInquiryId },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];
    loading(true);
    confirm &&
      promiseAccumulator(requests)
        .then(([response1]: any) => {
          loading(false);
          logger.info("Patient Approved:", response1);
          alert("Patient was approved");
          return [response1];
        })
        .then(([response1]) => {
          if (response1.statusText === "OK") {
            updatePatientNotes({
              user,
              authToken,
              data,
              note: "Patient was manually approved for ID verification",
            });
          }
        })
        .catch((error) => {
          logger.error(error);
        });
  };

  const handleDeleteApproval = (email: string) => {
    const confirm = window.confirm(
      "Are you sure you want to delete the ID verification task for this user?"
    );

    const requests = [
      () =>
        axios({
          method: "delete",
          url: `${apiEndpoint}admin/patient/${email}/idVerificationTask`,
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    confirm &&
      promiseAccumulator(requests)
        .then(([response1]: any) => {
          logger.info("Patient ID Verification task delete:", response1);
          alert("Patient task was deleted");
          return [response1];
        })
        .then(([response1]) => {
          if (response1.statusText === "OK") {
            updatePatientNotes({
              user,
              authToken,
              data,
              note: "Patient ID verification task was deleted",
            });
          }
        })
        .catch((error) => {
          logger.error(error);
        });
  };

  const handleDeleteFullBodySelfieTask = (email: string) => {
    const confirm = window.confirm(
      "Are you sure you want to delete the full body selfie task for this user?"
    );

    const requests = [
      () =>
        axios({
          method: "delete",
          url: `${apiEndpoint}admin/patient/${email}/fullBodySelfieTask`,
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];
    confirm &&
      promiseAccumulator(requests)
        .then(([response1]: any) => {
          logger.info("Patient full body selfie task delete:", response1);
          alert("Patient task was deleted");
          return [response1];
        })
        .then(([response1]) => {
          if (response1.statusText === "OK") {
            updatePatientNotes({
              user,
              authToken,
              data,
              note: "Patient full body selfie task was deleted",
            });
          }
        })
        .catch((error) => {
          logger.error(error);
        });
  };

  return (
    <Fragment>
      {data && data?.email && data?.idVerification ? (
        <div style={{ marginBottom: "12px" }}>
          <h3>ID Verification</h3>
          {(data.idVerification === IdVerificationStatus.INCOMPLETE ||
            data.idVerification === IdVerificationStatus.FAILED) && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <input
                className="personaInquiryIdInput"
                type={"text"}
                value={personaInquiryId}
                onChange={(e) => {
                  setPersonaInquiryId(e.target.value);
                }}
                style={{ width: "50%", marginBottom: 10 }}
              />
              {showPersonaIdError && (
                <div style={{ color: "red" }}>
                  Ensure your Persona Inquiry ID is valid
                </div>
              )}
              <button
                disabled={!personaIdIsValid}
                className={"cta magicLinkButton"}
                onClick={() => handleManualApproval(data.email)}
                style={{ maxWidth: "50%" }}
              >
                {"Manually Approve Patient"}
              </button>
            </div>
          )}
          {data.idVerification === IdVerificationStatus.FAILED && (
            <button
              className={"cta cancelUserButton"}
              onClick={() => handleDeleteApproval(data.email)}
            >
              {"Delete ID Verification Task"}
            </button>
          )}
          {data.idVerification === IdVerificationStatus.SUCCESS && (
            <p>ID has already been verified.</p>
          )}
        </div>
      ) : (
        <Fragment />
      )}
      {data && data?.email && data?.fullBodySelfie ? (
        <div>
          <div style={{ border: "1px solid #dbdada" }} />
          <h3>Full Body Selfie</h3>
          {data.fullBodySelfie.status === FullBodySelfieStatus.READY && (
            <p>FBS is ready to send.</p>
          )}
          {data.fullBodySelfie.status === FullBodySelfieStatus.SUBMITTED && (
            <p>FBS has been sent to the pharmacy.</p>
          )}
          {data.fullBodySelfie.status === FullBodySelfieStatus.FAILED && (
            <p>
              FBS has been rejected
              {data.fullBodySelfie.reasons &&
                data.fullBodySelfie.reasons.length > 0 &&
                `with reason ${data.fullBodySelfie.reasons[0]}`}
            </p>
          )}
          {data.fullBodySelfie.status === FullBodySelfieStatus.FAILED ||
            (data.fullBodySelfie.status === FullBodySelfieStatus.READY && (
              <button
                className={"cta cancelUserButton"}
                onClick={() => handleDeleteFullBodySelfieTask(data.email)}
              >
                {"Delete full body selfie task"}
              </button>
            ))}
          {!data.fullBodySelfie.status && <p>No FBS task found.</p>}
        </div>
      ) : (
        <Fragment />
      )}
      <Fragment>
      {data?.status === "FREE_TRIAL" && (
         <Fragment>
         <div style={{ border: "1px solid #dbdada" }} />
         <div style={{ display: "flex", alignItems: "center" }}>
           <h3 style={{ flex: 1 }}>Getting Ready Checklist Tasks</h3>
           {/* <button
             disabled={!arrayOfTasksToMarkIncomplete?.length}
             style={{ height: 40 }}
             className={"cta"}
             onClick={() => {
               handleMarkTasksIncomplete();
             }}
           >
             {"Save"}
           </button> */}
         </div>
         {gettingReadyTasks?.tasks?.map(
              (
                task: { title: string; complete: boolean; id: string },
                index: number
              ) => {
                let disabled = false;

                if ([FULL_BODY_SELFIE_TASK_ID, ID_VERIFICATION_TASK_ID, FIRST_DOSE_TAKEN_TASK_ID].includes(task.id) && !task?.complete) {
                  disabled = true
                }

                return (
                  <div key={index} style={{ display: "flex", padding: 2 }}>
                    <div style={{ display: "flex", flex: 1 }}>
                      {task?.title}
                    </div>
                    <Toggle
                    checked={task?.complete}
                    disabled={disabled}
                    icons={false}
                    onChange={(e) =>
                      toggleTask(
                        task.id,
                        e.target.checked,
                      )
                    }
                  />
                  </div>
                );
              }
            )}
          <div style={{ fontSize: 10, marginTop: 20 }}>
          Please note: some tasks may not show as incomplete after deleting as
          the logic for them depends on other factors, e.g. First dose taken
          relies on whether they have a selected start date
        </div>
         </Fragment>
      )}
      </Fragment>
    </Fragment>
  );
};
