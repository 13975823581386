import imperialToMetricWeight from "./imperialToMetricWeight";
import metricToImperialWeight from "./metricToImperialWeight";

const ST = [
  { label: "st", min: 6, max: 99 },
  { label: "lb", min: 0, max: 13 },
];
const KG = [{ label: "kg", min: 38, max: 635 }];

export interface weightDataProps {
  measurement: number[];
  unit: string;
  isValid: boolean[];
  kg?: any;
  st?: any;
  lb?: any;
}

export interface fieldProps {
  isValid: () => void;
  label: string;
  max: number;
  min: number;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
}

export interface entryProps {
  button: {
    label: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  };
  fields: fieldProps[];
}

export const getWeightInputData = ({
  weight,
  setWeight,
  setShowIndex,
  setDefaultFieldValues,
}: {
  weight: weightDataProps;
  setWeight: (arg: weightDataProps) => void;
  setShowIndex: (arg: number) => void;
  setDefaultFieldValues: (arg: weightDataProps) => void;
}) => [
  {
    fields: [
      {
        label: ST[0].label,
        name: ST[0].label,
        type: "number",
        onChange(e: React.ChangeEvent<HTMLInputElement>) {
          const newMeasurement = parseInt(e.target.value || "0", 10);
          setWeight({
            ...weight,
            measurement: [newMeasurement, weight?.measurement?.[1] || 0],
            isValid: [
              newMeasurement >= this.min && newMeasurement <= this.max,
              weight?.isValid?.[1] || false,
            ],
          });
        },
        min: ST[0].min,
        max: ST[0].max,
        isValid: () => weight?.isValid?.[0] || false,
      },
      {
        label: ST[1].label,
        name: ST[1].label,
        type: "number",
        onChange(e: React.ChangeEvent<HTMLInputElement>) {
          const newMeasurement = parseInt(e.target.value || "0", 10);
          setWeight({
            ...weight,
            measurement: [weight?.measurement?.[0] || 0, newMeasurement],
            isValid: [
              weight?.isValid?.[0] || false,
              newMeasurement >= this.min && newMeasurement <= this.max,
            ],
          });
        },
        min: ST[1].min,
        max: ST[1].max,
        isValid: () => weight?.isValid?.[1] || false,
      },
    ],
    button: {
      label: ST[0].label,
      onClick: () => {
        const measurement =
          weight.unit === ST[0].label
            ? weight.measurement
            : metricToImperialWeight(weight.measurement || [0]);
        const st = measurement?.[0] || 0;
        const lb = measurement?.[1] || 0;
        setWeight({
          measurement,
          unit: ST[0].label,
          isValid: [st <= ST[0].max || false, lb <= ST[0].max || false],
        });
        setDefaultFieldValues({
          ...weight,
          st,
          lb,
        });
        setShowIndex(0);
      },
    },
  },
  {
    fields: [
      {
        label: KG[0].label,
        name: KG[0].label,
        type: "number",
        onChange(e: React.ChangeEvent<HTMLInputElement>) {
          const newMeasurement = parseFloat(e.target.value || "0");
          setWeight({
            ...weight,
            measurement: [parseFloat(e.target.value || "0")],
            isValid: [
              (newMeasurement >= this.min && newMeasurement <= this.max) ||
                false,
            ],
          });
        },
        min: KG[0].min,
        max: KG[0].max,
        isValid: () => weight?.isValid?.[0] || false,
      },
    ],
    button: {
      label: KG[0].label,
      onClick() {
        const measurement =
          weight?.unit === KG[0]?.label
            ? weight.measurement
            : imperialToMetricWeight(weight.measurement || [0]);
        const kg = measurement?.[0] || 0;
        setWeight({
          measurement,
          unit: KG[0].label,
          isValid: [kg <= KG?.[0].max || false],
        });
        setDefaultFieldValues({
          ...weight,
          kg,
        });
        setShowIndex(1);
      },
    },
  },
];
