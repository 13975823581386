import Close from "../../images/incorrect.svg"
import axios from "axios"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../../helpers/firebaseHelper"
import "./../globals.css"
import { updatePatientNotes } from "../../helpers/updatePatientNotes"
import promiseAccumulator from "../../helpers/promiseAccumulator"
import { useCallback, useEffect, useState } from "react"
const appApiEndpoint = process.env.REACT_APP_API_FIREBASE_URL


export const RefundOrderModal = ({
  data,
  refresh,
  dismissModal,
}: {
  data: { [key: string]: any } | null
  refresh: () => void
  dismissModal: () => void
}) => {
  const [user] = useAuthState(auth)
  const [authToken, setAuthToken] = useState<string | undefined>()
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken()
    setAuthToken(token)
  }, [user])
  const [remainingAmount, setRemainingAmount] = useState<number>(
    0
  )
  const [amountToRefund, setAmountToRefund] = useState<number>(
    data?.payment.totalAmount
  )
  useEffect(() => {
    getAuthToken()
  }, [getAuthToken])

  useEffect(() => {
    const tempAmount = data?.payment.totalAmount - amountToRefund
    if (tempAmount < 0) {
      setAmountToRefund(parseFloat(data?.payment.totalAmount))
    } else {
      setRemainingAmount(data?.payment.totalAmount - amountToRefund)
    }
  }, [amountToRefund, data?.payment.totalAmount])

  const handleRefundOrder = () => {
    const confirm = window.confirm(
       `Are you sure you want to trigger a refund for ${amountToRefund} `
    )
    console.log("data at this point is", data)

    const requests = [
      () =>
        axios({
          method: "post",
          url: `${appApiEndpoint}admin/patient/${data?.email}/refund`,
          headers: {
            "x-api-key": `${process.env.REACT_APP_API_KEY}`,
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          data: {
            chargeId: data?.payment.chargeId,
            paymentAmountRemaining: remainingAmount
          },
        }),

    ]

    confirm &&
      promiseAccumulator(requests)
        .then(([response1]: any) => {
          if (response1.data === "Rate limited") {
            alert("Rate limited - try again in 10 minutes")
          } else if (response1?.statusText === "OK") {
            alert("Payment refund triggered. Refresh to check it was successful")
            updatePatientNotes({
              user,
              authToken,
              data: { email: data?.email },
              note: `Payment (${data?.payment.chargeId}) was refunded.`,
            })
          }
          return [response1]
        })
        .then(() => {
          refresh()
        })
        .catch((error) => {
          console.log(
            `Error triggering refund for ${data?.email}.`,
            error
          )
          alert(error)
        })
  }

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#e3e6df99",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "60%",
          minHeight: "60%",
          backgroundColor: "white",
          margin: "auto",
          marginTop: "5%",
          borderRadius: 8,
          padding: 24,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: 12,
          }}
        >
          <img
            src={Close}
            alt=""
            onClick={() => {
              dismissModal()
            }}
            style={{ maxHeight: 48, maxWidth: 48 }}
          />
        </div>
        <div>
          <h1>Refund a payment</h1>
          <p>
            This will trigger a refund request with the payment processor. Once the processor tells us a refund has suceeded the server will update the status of this payment in our database.
          </p>
          <h4>Amount to refund</h4>
          <div style={{marginBottom: '10px'}}>
            <h5>Quick calc</h5>
            <button className="cta" style={{marginRight: '10px'}} onClick={() => {
              setAmountToRefund(data?.payment.totalAmount * 0.1)
            }}>10%</button>
            <button className="cta" style={{marginRight: '10px'}} onClick={() => {
              setAmountToRefund(data?.payment.totalAmount * 0.2)
            }}>20%</button>
            <button style={{marginRight: '10px'}} className="cta" onClick={() => {
              setAmountToRefund(data?.payment.totalAmount * 0.3)
            }}>30%</button>
            <button className="cta" onClick={() => {
              setAmountToRefund(data?.payment.totalAmount)
            }}>100%</button>
          </div>
          <h5>Other Amount</h5>
          <input
            type="number" 
            min={0.01}
            max={parseFloat(data?.payment.totalAmount)}
            onChange={(e) => {
              setAmountToRefund(parseFloat(e.target.value))
            }}
            defaultValue={amountToRefund}
          />
          <h4>
            Remaining: {remainingAmount.toFixed(2)}
          </h4>



          <div
            style={{
              alignContent: "center",
              textAlign: "center",
              marginTop: 24,
            }}
          >
            <p>Are you sure you want to issue a refund?</p>
            <button
              className="cta cancelUserButton"
              onClick={() => {
                handleRefundOrder()
              }}
            >
              {"Trigger refund"}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
