import Close from "../images/incorrect.svg";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import logger from "../helpers/logger";
import "./globals.css";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import promiseAccumulator from "../helpers/promiseAccumulator";
import { apiEndpoint } from "./dashboard";
import { useCallback, useEffect, useState } from "react";
import { ItemComponent, MealProductData } from "./create-patient-meal-order";

const translateOrderToDictionary = (order: any) => {
  if (!order) {
    return null;
  }
  const dict: any = {};
  Object.entries(order).forEach(([key, item]: any) => {
    dict[item.barcodeNumber] = item;
  });
  return dict;
};

export const PatchOrderModal = ({
  data,
  email,
  existingOrder,
  productData,
  refresh,
  dismissModal,
}: {
  data: { [key: string]: any } | null;
  refresh: () => void;
  dismissModal: () => void;
  email: string;
  existingOrder: any;
  productData: MealProductData | undefined;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const [mealOrder, setMealOrder] = useState<{
    [key: string]: { quantity: number; name?: string };
  }>(translateOrderToDictionary(existingOrder?.items));
  const [isLoading, setIsLoading] = useState(false);

  if (!existingOrder) {
    return null;
  }

  const handlePatchMealOrder = () => {
    const requests = [
      () =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/patient/${email}/mealOrder/${existingOrder.databaseId}`,
          data: { orderItems: { ...mealOrder } },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];
    setIsLoading(true);
    promiseAccumulator(requests)
      .then(([response1]: any) => {
        logger.info("Patch meal order", response1);
        setIsLoading(false);
        alert("Meal Order has been editted");
        return [response1];
      })
      .then(([response1]) => {
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: "Patient meal order was editted",
          });
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#e3e6df99",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
          minHeight: "60%",
          backgroundColor: "white",
          margin: "auto",
          marginTop: "5%",
          borderRadius: 8,
          padding: 24,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: 12,
          }}
        >
          <img
            src={Close}
            alt=""
            onClick={() => {
              dismissModal();
            }}
            style={{ maxHeight: 48, maxWidth: 48 }}
          />
        </div>
        <div>
          <h1>Edit this order?</h1>
          <p>
            This will edit the order in the app database. If the order has not
            yet gone to shopify then the new items will pull through, however if
            this order has already been placed changing the items here will only
            mean new items show in the app, it cannot change an order that has
            already been placed. Please reach out to the fullfillment center if
            you want to do that!
          </p>
          <div>
            {productData?.orderScreenDetails &&
              Object.entries(
                productData?.orderScreenDetails.productCategories
              ).map(([key, category]) => {
                return (
                  <div style={{ marginBottom: 20 }}>
                    <div style={{ fontSize: 24, marginBottom: 5 }}>{key}</div>
                    <div>
                      {Object.entries(category.products).map(
                        ([key, product]) => {
                          return (
                            <ItemComponent
                              setCount={(n) => {
                                setMealOrder({
                                  ...mealOrder,
                                  [product.barcodeNumber]: {
                                    quantity: n,
                                  },
                                });
                              }}
                              count={
                                mealOrder[product.barcodeNumber]?.quantity ?? 0
                              }
                              key={product.barcodeNumber}
                              product={product}
                              hideStock={true}
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                );
              })}
            <hr />
            <div style={{ fontSize: 24, marginBottom: 5 }}>Extras</div>
            <ItemComponent
              setCount={(n) => {
                setMealOrder({
                  ...mealOrder,
                  "HB-SB-1022": {
                    quantity: n,
                  },
                });
              }}
              count={mealOrder["HB-SB-1022"]?.quantity ?? 0}
              key={"shaker"}
              product={{
                barcodeNumber: "HB-SB-1022",
                stock: "",
                title: "Habitual shaker bottle",
              }}
              hideStock={true}
            />
          </div>

          <div
            style={{
              alignContent: "center",
              textAlign: "center",
              marginTop: 24,
            }}
          >
            <button
              disabled={isLoading}
              className="cta cancelUserButton"
              onClick={() => {
                handlePatchMealOrder();
              }}
            >
              {"Update this order"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
