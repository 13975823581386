const patientNotesTags = [
  "Medical",
  "Important",
  "Patient Update",
  "HeyCeres Note",
  "Call",
  "Meal Ordering",
];

export default patientNotesTags;
