import axios from "axios";
import { apiEndpoint } from "./dashboard";
import "react-toggle/style.css";
import Toggle from "react-toggle";

export type FeatureFlag = {
  title: string;
  feature: string;
  isOn: boolean;
  data?: number | any;
  options?: { label: string; value: number }[] | any[];
};

const toggleFlag = async (
  feature: string,
  isOn: boolean,
  email: string,
  setFlags: any,
  isloading: (value: boolean) => void,
  authToken: string | undefined,
  data?: { defaultPharmacy: number } | any
) => {
  try {
    isloading(true);
    await axios({
      method: "post",
      url: `${apiEndpoint}admin/patient/${email}/featureFlags`,
      headers: {
        "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        feature,
        isOn,
        data,
      },
    })
      .then((response: { data: FeatureFlag[] }) => {
        isloading(false);
        if (response?.data) {
          setFlags(response.data);
        }
      })
      .catch((error: any) => {
        isloading(false);
        console.log(error.message);
      });
  } catch (error) {
    isloading(false);
    alert("Something went wrong toggling flag!");
    console.log(error);
  }
};

export enum FeatureFlags {
  TRIAL_PATIENT = "TRIAL_PATIENT",
  BLOOD_GLUCOSE = "BLOOD_GLUCOSE",
  BLOOD_PRESSURE = "BLOOD_PRESSURE",
  TEST_USER = "TEST_USER",
  DEFAULT_PHARMACY = "DEFAULT_PHARMACY",
}

export const EditFeatureFlags = ({
  email,
  data,
  isLoading,
  setFlags,
  authToken,
}: {
  email: string | null;
  data: FeatureFlag[] | null;
  isLoading: (input: boolean) => void;
  setFlags: (data: FeatureFlag[]) => void;
  authToken: string | undefined;
}) => {
  if (!data || !email) {
    return <div>No feature flags!</div>;
  } else {
    return (
      <div className={"patientNoteData"}>
        {data.map((flag: FeatureFlag) => {
          if (flag.options?.length && flag.options.length > 0) {
            return (
              <div key={flag.feature + flag.isOn}>
                <span>{flag.title}</span>
                <label>
                  <select
                    defaultValue={flag.data}
                    onChange={(e) => {
                      if (e.target.value !== flag.data) {
                        toggleFlag(
                          flag.feature,
                          true,
                          email,
                          setFlags,
                          isLoading,
                          authToken,
                          { defaultPharmacy: e.target.value }
                        );
                      }
                    }}>
                    {flag.options?.map((op) => {
                      return <option value={op.value}>{op.label}</option>;
                    })}
                  </select>
                </label>
              </div>
            );
          } else {
            return (
              <div key={flag.feature + flag.isOn}>
                <span>{flag.title}</span>
                <label>
                  <Toggle
                    checked={flag.isOn}
                    disabled={flag.feature === FeatureFlags.TRIAL_PATIENT}
                    icons={false}
                    onChange={(e) =>
                      toggleFlag(
                        flag.feature,
                        e.target.checked,
                        email,
                        setFlags,
                        isLoading,
                        authToken
                      )
                    }
                  />
                </label>
              </div>
            );
          }
        })}
      </div>
    );
  }
};
