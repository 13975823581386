import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import "./globals.css";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import logger from "../helpers/logger";
import promiseAccumulator from "../helpers/promiseAccumulator";
import { ProgrammeType } from "../helpers/programmeHelper";

export const ChangeStatus = ({
  data,
  refresh,
}: {
  data: {
    firstName: string;
    status: string;
    email: string;
    hubspotId: string;
    patientType: ProgrammeType;
  } | null;
  refresh: () => void;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const { firstName, status, email, hubspotId } = data || {};
  const [currentStatus, setCurrentStatus] = useState(status);
  const [cancellationReason, setCancellationReason] = useState<
    string | undefined
  >();
  const [moreInfo, setMoreInfo] = useState<string | undefined>();
  const [setupSubscriptions, setSetupSubscriptions] = useState(false);
  const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
  const handleChangeStatus = () => {
    const requests = [];
    if (setupSubscriptions) {
      requests.push(() =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/patient/${email}/setToActive/`,
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        })
      );
    } else {
      requests.push(() =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/v2/patient/`,
          data: {
            email: email,
            status: currentStatus,
            hubspotId,
            cancellationReason,
            moreInfo: showOtherInputField ? moreInfo : undefined,
          },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        })
      );
    }

    promiseAccumulator(requests)
      .then(([response1]: any) => {
        logger.info("Status Changed:", response1);
        return [response1];
      })
      .then(([response1]) => {
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: `Status was changed from ${status} to ${currentStatus}`,
          });
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  const showCancelReasonDropDown = useMemo(() => {
    return currentStatus === "CHURNED";
  }, [currentStatus]);
  const showOtherInputField = useMemo(() => {
    return cancellationReason === "GLP cancel other reason";
  }, [cancellationReason]);

  return (
    <Fragment>
      {status ? (
        <div className={"changeStatus cascade centre"}>
          <label>{`${firstName}'s current status is:`}</label>
          <div>
            <select
              onChange={(e) => {
                if (
                  currentStatus === "FREE_TRIAL" &&
                  e.target.value === "ACTIVE"
                ) {
                  if (data?.patientType !== ProgrammeType.MOMENTA) {
                    setSetupSubscriptions(true);
                  }
                  setCurrentStatus(e.target.value);
                } else if (
                  currentStatus === "ACTIVE" &&
                  e.target.value === "FREE_TRIAL"
                ) {
                  //  alert("Please use reset to welcome guide");
                } else {
                  setSetupSubscriptions(false);
                  setCurrentStatus(e.target.value);
                }
              }}
              defaultValue={currentStatus}
            >
              <option value={"FREE_TRIAL"}>{"FREE_TRIAL"}</option>
              <option value={"ONBOARDING"}>{"ONBOARDING"}</option>
              <option value={"ACTIVE"}>{"ACTIVE"}</option>
              <option value={"CHURNED"}>{"CHURNED"}</option>
            </select>
            <button
              disabled={
                (showCancelReasonDropDown && !cancellationReason) ||
                (showCancelReasonDropDown && showOtherInputField && !moreInfo)
              }
              className={"cta"}
              onClick={handleChangeStatus}
            >
              {"SAVE!"}
            </button>
          </div>
          {showCancelReasonDropDown && (
            <div
              style={{
                marginTop: 20,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: 10 }}>Cancellation reason:</div>
                <select
                  onChange={(e) => {
                    setCancellationReason(e.target.value);
                  }}
                >
                  <option value="" disabled selected>
                    Select cancellation reason
                  </option>
                  <option value={"I need more support"}>
                    {"Patient needs more support"}
                  </option>
                  <option value={"I am experiencing side effects"}>
                    {"Patient experiencing side effects"}
                  </option>
                  <option value={"Weight loss results below expectation"}>
                    {"Weight loss results below expectation"}
                  </option>
                  <option value={"I can’t afford the monthly payments"}>
                    {"Patient can’t afford the monthly payments"}
                  </option>
                  <option value={"I’m struggling to use the app"}>
                    {"Patient struggling to use the app"}
                  </option>
                  <option value={"GLP cancel other reason"}>Other</option>
                </select>
              </div>
              {showOtherInputField && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>Please provide more info:</div>
                  <input
                    value={moreInfo}
                    onChange={(e) => {
                      setMoreInfo(e.target.value);
                    }}
                    multiple
                    style={{ height: 50 }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};
