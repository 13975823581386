import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import React, { useState, useCallback, useEffect } from "react";
import "./globals.css";
import logger from "../helpers/logger";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import promiseAccumulator from "../helpers/promiseAccumulator";

export const DelayMealOrder = ({
  data,
  refresh,
}: {
  data: {
    email: string;
  } | null;
  refresh: () => void;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const { email } = data || {};

  const [weeksToDelay, setWeeksToDelay] = useState(0);
  const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
  const handleChangeWeekNumber = () => {
    const requests = [
      () =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/patient/${email}/paymentDate`,
          data: {
            weeksToDelay,
          },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    promiseAccumulator(requests)
      .then(([response1]: any) => {
        logger.info("Meal order delay respose", response1);
        return [response1];
      })
      .then(([response1]) => {
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: `Meal order date was changed by ${weeksToDelay} weeks`,
          });
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        logger.error(error);
      });
  };
  const disabled = weeksToDelay === 0;
  return (
    <div>
      <label>{`Change patients meal order date forward or backwards in weeks: `}</label>
      <input
        onChange={(e) => {
          setWeeksToDelay(Number(e.target.value));
        }}
        type={"number"}
        defaultValue={weeksToDelay}
      />
      <button
        disabled={disabled}
        style={disabled ? { cursor: "not-allowed" } : {}}
        className={"cta"}
        onClick={handleChangeWeekNumber}
      >
        {"SAVE!"}
      </button>
    </div>
  );
};
