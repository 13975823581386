import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import {
  differenceInWeeks,
  nextMonday,
  parseISO,
  format,
  addWeeks,
} from "date-fns";
import { useCallback, useEffect, useState } from "react";
import "./globals.css";
import logger from "../helpers/logger";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import promiseAccumulator from "../helpers/promiseAccumulator";

export const nextMondays = (
  currentSelection: any,
  setInitialSelection: any,
  numberOfMondays?: number
) => {
  const numberOfOptions = numberOfMondays || 8;
  const options = [];
  for (let i = 0; i < numberOfOptions; i++) {
    options.push(addWeeks(nextMonday(new Date()), i));
  }
  const firstOption = format(options[0], "yyyy-MM-dd");
  if (firstOption !== currentSelection) {
    setInitialSelection(format(options[0], "yyyy-MM-dd"));
  }

  return options.map((o, index) => {
    const value = format(o, "yyyy-MM-dd");
    const selected = value === currentSelection ? true : undefined;
    return (
      <option
        key={`startdateoption-${index}`}
        selected={selected}
        value={value}
      >
        {format(o, "dd/MM/yyyy")}
      </option>
    );
  });
};

export const ChangeStartDate = ({
  data,
  refresh,
}: {
  data: {
    name: string;
    selectedStartDate: string;
    programmeStage: { startDate: string; week: number };
    email: string;
    hubspotId: string;
    status: string;
  } | null;
  refresh: () => void;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const { selectedStartDate, email, hubspotId, programmeStage } = data || {};
  const { startDate } = programmeStage || {};
  const [currentSelectedStartDate, setCurrentSelectedStartDate] =
    useState(selectedStartDate);
  const [options, setOptions] = useState<any>();
  const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
  const handleChangeSelectedStartDate = () => {
    const requests = [
      () =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/patient/${email}/selectedStartDate`,
          data: {
            email: email,
            startDate: currentSelectedStartDate,
            hubspotId,
            weeksToDelay:
              selectedStartDate && currentSelectedStartDate
                ? differenceInWeeks(
                    parseISO(currentSelectedStartDate),
                    parseISO(selectedStartDate)
                  )
                : 0,
          },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    promiseAccumulator(requests)
      .then(([response1]: any) => {
        logger.info("Change Selected Start Date:", response1);
        return [response1];
      })
      .then(([response1]) => {
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: `Selected start date was changed from ${
              selectedStartDate || startDate
            } to ${currentSelectedStartDate}`,
          });
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  const handleClearSelectedStartDate = () => {
    const requests = [
      () =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/patient/${email}/selectedStartDate`,
          data: {
            email: email,
            startDate: null,
            hubspotId,
          },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    promiseAccumulator(requests)
      .then(([response1]: any) => {
        logger.info("Cleared Selected Start Date:", response1);
        return [response1];
      })
      .then(([response1]) => {
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: `Selected start date was cleared. Selected start date was ${selectedStartDate}`,
          });
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  useEffect(() => {
    const newOptions = nextMondays(
      currentSelectedStartDate,
      setCurrentSelectedStartDate,
      8
    );
    setOptions(newOptions);
  }, [currentSelectedStartDate]);

  return (
    <div>
      <label>{`Change scheduled start date to: `}</label>
      <select
        onChange={(e) => {
          setCurrentSelectedStartDate(e.target.value);
        }}
        // doestn seem to work...
        // defaultValue={currentSelectedStartDate}
      >
        {options}
      </select>
      <button className={"cta"} onClick={handleChangeSelectedStartDate}>
        {"SAVE!"}
      </button>
      {selectedStartDate && (
        <button className={"cta"} onClick={handleClearSelectedStartDate}>
          {"Clear selected date"}
        </button>
      )}
    </div>
  );
};
