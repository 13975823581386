import axios from "axios";
import logger from "./logger";

const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;

/**
 * @name updatePatientNotes
 * @description Makes an XHTTP request to the patient endpoint; which then adds a new note to the database
 * @param {Object} user
 * @param {string} authToken
 * @param {Object} data
 * @param {string} note
 * @param {string[]} tags
 * @param {boolean} autoNote
 */
export const updatePatientNotes = (
  {
    user,
    authToken,
    data,
    note,
    tags = [],
    autoNote = true,
  }: {
    user: any;
    authToken: any;
    data: any;
    note: string | null | undefined;
    tags?: string[] | null | undefined;
    autoNote?: boolean;
  } = {
    user: "",
    authToken: "",
    data: {},
    note: "",
    tags: [],
    autoNote: true,
  }
) => {
  return new Promise((resolve, reject) => {
    const tagsToAdd = autoNote ? ["Patient Update", "HeyCeres Note"] : tags;

    if (!user) {
      alert(
        "No user has been attributed to this note. Please contact a developer."
      );
      reject();
      return;
    }

    if (!data?.email) {
      alert(
        "The email address for the patient is required to record a note. Please contact a developer."
      );
      reject();
      return;
    }

    if (!note) {
      alert("Please add a note before submitting");
      reject();
      return;
    }

    if (!tagsToAdd?.length) {
      alert("Please add a tag(s) to the entry before submitting");
      reject();
      return;
    }

    const path = `admin/patient/${data?.email}`;
    const headers = {
      "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    };

    return axios({
      method: "post",
      url: `${apiEndpoint}${path}/notes`,
      headers,
      data: {
        writtenBy: user?.displayName,
        note,
        tags: tagsToAdd,
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch(() => {
        logger.error("Patient Notes update failed");
      });
  });
};
