import axios from "axios";
import { Fragment, useCallback, useEffect, useState } from "react";
import logger from "../helpers/logger";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import {
  getProgrammeCmsIdFromName,
  getProgrammeName,
  ProgrammeAnalyticsName,
  TrialProgrammeAnalyticsName,
} from "../helpers/programmeHelper";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import "./globals.css";
import promiseAccumulator from "../helpers/promiseAccumulator";

function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

const getNames = (isTrialPatient?: boolean) => {
  const options = [];
  options.push(<option disabled>───── TDR ─────</option>);

  for (const name of enumKeys(
    isTrialPatient ? TrialProgrammeAnalyticsName : ProgrammeAnalyticsName
  )) {
    options.push(
      <option key={`nameoption-${name}`} value={ProgrammeAnalyticsName[name]}>
        {ProgrammeAnalyticsName[name]}
      </option>
    );
    if ((name as unknown) === "OG_TRIAL") {
      options.push(<option disabled>───── GLP ─────</option>);
    } else if ((name as unknown) === "PREMIUM") {
      options.push(<option disabled>───── Trial ─────</option>);
    } else if ((name as unknown) === "LINDUS_TRIAL") {
      options.push(<option disabled>───── Old ─────</option>);
    }
  }
  return options;
};

export const ChangeProgramme = ({
  data,
  refresh,
}: {
  data: {
    programmeStatus: { programmeId: string };
    email: string;
    hubspotId: string;
    status: string;
    trialPatient?: boolean;
  } | null;
  refresh: () => void;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const { programmeStatus, email, hubspotId, trialPatient } = data || {};
  const [currentProgrammeName, setCurrentProgrammeName] = useState(
    programmeStatus?.programmeId
      ? getProgrammeName(programmeStatus.programmeId).name
      : "unknown"
  );
  const [currentProgrammeCmsId, setCurrentProgrammmeCmsId] = useState(
    programmeStatus?.programmeId
  );

  useEffect(() => {
    setCurrentProgrammmeCmsId(getProgrammeCmsIdFromName(currentProgrammeName));
  }, [currentProgrammeName]);

  const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
  const handleChangeProgramme = () => {
    const requests = [
      () =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/patient/${email}/programme`,
          data: {
            programmeCmsId: currentProgrammeCmsId,
            hubspotId,
          },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    promiseAccumulator(requests)
      .then(([response1]: any) => {
        logger.info("Changed Programme CMS ID:", response1);
        return [response1];
      })
      .then(([response1]) => {
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: `Programme CMS ID was updated from ${
              programmeStatus?.programmeId
                ? getProgrammeName(programmeStatus.programmeId).name
                : "unknown"
            } to ${currentProgrammeName}`,
          });
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  return (
    <Fragment>
      <div>
        <label>{`Change programme content to: `}</label>
        <select
          onChange={(e) => {
            setCurrentProgrammeName(e.target.value);
          }}
          defaultValue={currentProgrammeName}
        >
          {getNames(trialPatient)}
        </select>
        <button className={"cta"} onClick={handleChangeProgramme}>
          {"SAVE!"}
        </button>
      </div>
    </Fragment>
  );
};
