import Close from "../images/incorrect.svg";
import "./globals.css";
import { useMemo, useRef } from "react";
import "../styles/ag-grid.css";
import "../styles/ag-theme-habitual.css";
import { AgGridReact } from "ag-grid-react";
import "../styles/ag-grid.css";
import "../styles/ag-theme-habitual.css";
import "./globals.css";
import { MedicationReview } from "./medical-review-responses";
export const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;

export const MedicalReviewCompareModal = ({
  data,
  dismissModal,
}: {
  data: { [key: number]: MedicationReview };
  dismissModal: () => void;
}) => {
  const responses = useMemo(() => {
    const result = Object.values(data).map((medicationReview) => {
      let formattedQuestions = {};
      Object.values(medicationReview.medicationReviewAnswers).forEach(
        (question) => {
          formattedQuestions = {
            ...formattedQuestions,
            medicationReviewId: medicationReview.id,
            question: question.question,
            questionId: question.questionId,
            [`answer-${question.questionId}`]: question.answer,
          };
        }
      );
      return formattedQuestions;
    });
    return result;
  }, [data]);

  const questionsToUse = useMemo(() => {
    const questionsArray: { question: string; questionId: string }[] = [];
    const items = Object.values(data);
    items.map((medicationReview) => {
      const questions = Object.values(medicationReview.medicationReviewAnswers);
      return questions.map((question) => {
        const index = questionsArray.findIndex((q) => {
          return q.questionId === question.questionId;
        });
        if (index === -1) {
          // add it
          return questionsArray.push(question);
        } else {
          // we already have it
          return undefined;
        }
      });
    });
    return questionsArray;
  }, [data]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
    }),
    []
  );

  const gridRef: any = useRef();

  const columnsToUse = useMemo(() => {
    return questionsToUse.map((item) => {
      return {
        headerName: item.question,
        field: `answer-${item.questionId}`,
        resizable: true,
      };
    });
  }, [questionsToUse]);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: 50,
      }}
    >
      <div
        className="ag-theme-alpine"
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#EBF4F4",
          borderRadius: 25,
          padding: 20,
          boxShadow: "0px 2.02062px 7.07216px rgba(44, 74, 67, 0.1)",
          opacity: 100,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            onClick={() => {
              dismissModal();
            }}
          >
            <img
              src={Close}
              alt=""
              style={{
                maxHeight: 48,
                maxWidth: 48,
              }}
            />
          </button>
        </div>
        <AgGridReact
          ref={gridRef}
          rowData={responses} // Row Data for Rows
          columnDefs={[
            {
              headerName: "Medication Review ID",
              field: "medicationReviewId",
              filter: true,
              resizable: true,
            },
            ...columnsToUse,
          ]} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          onCellClicked={() => {
            console.log("Clicked");
          }} // Optional - registering for Grid Event
        />
      </div>
    </div>
  );
};
