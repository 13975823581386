export const ID_VERIFICATION_TASK_ID = "cley085d1eolg0bteh19f1hfc";
export const FULL_BODY_SELFIE_TASK_ID = "clk9bwjzra2a90bujz0oak521";
export const FIRST_DOSE_TAKEN_TASK_ID = "clex1itsm0obq0bte0f0vgh0x";

export enum IdVerificationStatus {
  INCOMPLETE = "INCOMPLETE",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS",
}

export enum FullBodySelfieStatus {
  READY = "READY",
  FAILED = "FAILED",
  SUBMITTED = "SUBMITTED",
}

export enum TipDeclinedConsultationReasonCodes {
  PHOTO_QUALITY = "PHOTO_QUALITY",
  PHOTO_LIGHTING = "PHOTO_LIGHTING",
  PHOTO_CLOTHING = "PHOTO_CLOTHING",
  PHOTO_CRITERIA = "PHOTO_CRITERIA",
  PHOTO_FACE = "PHOTO_FACE",
  IDENTITY_NOT_CONFIRMED = "IDENTITY_NOT_CONFIRMED",
  NOT_SUITABLE = "NOT_SUITABLE",
}

export interface OrderItem {
  sku: OrderItemSku;
  longTitle?: string;
  category?: "meals" | "electrolytes" | "weightLossPlan";
  subcategory?: "soups" | "shakes" | "porridges" | "electrolytes";
  quantity?: number;
}

export type OrderItemSku =
  | CmsMedicationEnum
  | CmsElectrolyteEnum
  | CmsMealsEnum
  | "NEEDLES"
  | "SHARPS_BIN"
  | "APP"
  | "FIBRE_30"
  | "SHAKER"
  | "BLOODS"
  | "TASTER_BOX_VEGAN"
  | "TASTER_BOX"
  | "DIGITAL_SCALES"
  | "CGM"
  | TdrProgrammeSkus;

export enum CmsMealsEnum {
  GREEN = "GREEN_SOUP",
  TOMATO = "TOMATO_SOUP",
  CARROT = "CARROT_SOUP",
  BANANA = "BANANA_PORRIDGE",
  MORNING = "MORNING_PORRIDGE",
  CHOCOLATE = "CHOCOLATE_SHAKE",
  CARAMEL = "CARAMEL_SHAKE",
  CHAI = "CHAI_SHAKE",
  STRAWBERRY = "STRAWBERRY_SHAKE",
  VANILLA = "VANILLA_SHAKE",
}

export enum CmsElectrolyteEnum {
  LIME = "ELECTROLYTES_LIME",
  WATERMELON = "ELECTROLYTES_WATERMELON",
  BERRY = "ELECTROLYTES_BERRY",
  ORANGE = "ELECTROLYTES_ORANGE",
}

export enum TdrProgrammeSkus {
  TWO_DAY = "2/day",
  FOUR_DAY = "4/day",
}
export enum CmsMedicationEnum {
  Ozempic_025 = "Ozempic_025",
  Ozempic_050 = "Ozempic_050",
  Ozempic_100 = "Ozempic_100",
  Rybelsys_3 = "Rybelsys_3",
  Rybelsys_7 = "Rybelsys_7",
  Rybelsys_14 = "Rybelsys_14",
  Wegovy_025 = "Wegovy_025",
  Wegovy_050 = "Wegovy_050",
  Wegovy_100 = "Wegovy_100",
  Wegovy_170 = "Wegovy_170",
  Wegovy_240 = "Wegovy_240",
  Mounjaro_25 = "Mounjaro_25",
  Mounjaro_50 = "Mounjaro_50",
  Mounjaro_75 = "Mounjaro_75",
  Mounjaro_100 = "Mounjaro_100",
  Mounjaro_125 = "Mounjaro_125",
  Mounjaro_150 = "Mounjaro_150",
}
