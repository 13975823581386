import { useState } from "react";

export const ozempicOptions = [
  <option key={"Ozempic 0.25"} value={"Ozempic 0.25"}>
    {"Ozempic 0.25"}
  </option>,
  <option key={"Ozempic 0.5"} value={"Ozempic 0.5"}>
    {"Ozempic 0.5"}
  </option>,
  <option key={"Ozempic 1"} value={"Ozempic 1"}>
    {"Ozempic 1"}
  </option>,
];

export const wegovyOptions = [
  <option key={"Wegovy 0.25"} value={"Wegovy 0.25"}>
    {"Wegovy 0.25"}
  </option>,
  <option key={"Wegovy 0.50"} value={"Wegovy 0.50"}>
    {"Wegovy 0.50"}
  </option>,
  <option key={"Wegovy 1"} value={"Wegovy 1"}>
    {"Wegovy 1"}
  </option>,
  <option key={"Wegovy 1.7"} value={"Wegovy 1.7"}>
    {"Wegovy 1.7"}
  </option>,
  <option key={"Wegovy 2.4"} value={"Wegovy 2.4"}>
    {"Wegovy 2.4"}
  </option>,
];

export const mounjaroOptions = [
  <option key={"Mounjaro 2.5"} value={"Mounjaro 2.5"}>
    {"Mounjaro 2.5"}
  </option>,
  <option key={"Mounjaro 5"} value={"Mounjaro 5"}>
    {"Mounjaro 5.0"}
  </option>,
  <option key={"Mounjaro 7.5"} value={"Mounjaro 7.5"}>
    {"Mounjaro 7.5"}
  </option>,
  <option key={"Mounjaro 10"} value={"Mounjaro 10"}>
    {"Mounjaro 10"}
  </option>,
  <option key={"Mounjaro 12.5"} value={"Mounjaro 12.5"}>
    {"Mounjaro 12.5"}
  </option>,
    <option key={"Mounjaro 15"} value={"Mounjaro 15"}>
    {"Mounjaro 15"}
  </option>,
];

export const rybelsusOptions = [
  <option key={"Rybelsus 3"} value={"Rybelsus 3"}>
    {"Rybelsus 3"}
  </option>,
  <option key={"Rybelsus 7"} value={"Rybelsus 7"}>
    {"Rybelsus 7"}
  </option>,
  <option key={"Rybelsus 14"} value={"Rybelsus 14"}>
    {"Rybelsus 14"}
  </option>,
];

export const RowBottomContainer = ({
  handleResetTask,
  handleUpdateNewDosage,
  row,
}: {
  handleResetTask: () => void;
  handleUpdateNewDosage: (updatedNewDosage: string) => void;
  row: any;
}) => {
  const [selectedDosage, setSelectedDosage] = useState<string>(
    row?.medicationReview?.newDosage || null
  );
  return (
    <div style={{ margin: 12 }}>
      {row.prescriptionPayment.status === "UPCOMING" &&
        row.medicationReview && (
          <div style={{ margin: 8 }}>
            {" "}
            <select
              onChange={(e) => {
                setSelectedDosage(e.target.value);
              }}
              defaultValue={row?.medicationReview?.newDosage}
            >
              {row.prescriptionPayment.product === "Rybelsus"
                ? rybelsusOptions
                : row.prescriptionPayment.product === "Wegovy"
                ? wegovyOptions
                : row.prescriptionPayment.product === "Mounjaro"
                ? mounjaroOptions
                : ozempicOptions}
            </select>
            <button
              style={{ marginLeft: 12 }}
              className={"cta"}
              onClick={() => handleUpdateNewDosage(selectedDosage)}
            >
              {"Update patient's next dosage"}
            </button>
          </div>
        )}
      {row.prescriptionPayment.status === "UPCOMING" &&
        row.medicationReview && (
          <button className={"cta cancelUserButton"} onClick={handleResetTask}>
            Reset medication review task
          </button>
        )}
      {["PAID", "PART_REFUNDED"].includes(row.prescriptionPayment.status) &&
        row?.medicationReview?.potentialCancellation &&
        row.medicationReview && (
          <button className={"cta cancelUserButton"} onClick={handleResetTask}>
            Reset medication review task
          </button>
        )}
    </div>
  );
};
