import { parseISO, format } from "date-fns";
import { Fragment, useCallback, useEffect, useState } from "react";
import { getProgrammeName, ProgrammeType } from "../helpers/programmeHelper";
import "./globals.css";
import { isStaging } from "./manage-patients";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";

export const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;

export const PatientData = ({
  data,
}: {
  data: {
    fullname: string;
    status: string;
    level: any;
    isRisk: boolean;
    currentWeight: any;
    selectedStartDate: string;
    startWeight: any;
    weekNumber: number;
    email: string;
    hubspotId: string;
    programmeStatus: { programmeId: string };
    programmeStage: { startDate: string; week: number };
    pendingCancellation: boolean;
    trialPatient: boolean;
    patientType: ProgrammeType;
    medication: string;
  } | null;
}) => {
  const {
    fullname,
    status,
    level,
    isRisk,
    currentWeight,
    startWeight,
    selectedStartDate,
    email,
    hubspotId,
    programmeStatus,
    programmeStage,
    pendingCancellation,
    trialPatient,
    patientType,
    medication,
  } = data || {};
  const { week: weekNumber } = programmeStage || {};
  const patientWeight = currentWeight?.[0] || {};
  const patientStartWeight = startWeight?.[0] || {};
  const [tempFullname, setTempFullname] = useState(fullname);
  // const [tempEmail, setTempEmail] = useState(email);
  const [user] = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);

  const submitNameChange = useCallback(async () => {
    setLoading(true);
    await axios({
      method: "patch",
      url: `${apiEndpoint}admin/patient/${email}/name`,
      headers: {
        "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
        Authorization: `Bearer ${authToken}`,
      },
      data: { fullname: tempFullname },
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          alert(
            "Please check Shopify has updated correctly as it has been weird on name changes. It can take 30 seconds to update"
          );
          return;
        } else {
          setLoading(false);
          throw new Error(response.data);
        }
      })
      .catch((err) => {
        alert(`Error on changing patient name, ${err}`);
        setLoading(false);
      });
  }, [authToken, email, tempFullname]);
  // const submitEmailChange = useCallback(async () => {
  //   setLoading(true);
  //   await axios({
  //     method: "patch",
  //     url: `${apiEndpoint}admin/patient/${email}/email`,
  //     headers: {
  //       "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
  //       Authorization: `Bearer ${authToken}`,
  //     },
  //     data: { newEmail: tempEmail },
  //   })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setLoading(false);
  //         alert("Email updated");
  //         return;
  //       } else {
  //         setLoading(false);
  //         throw new Error(response.data);
  //       }
  //     })
  //     .catch((err) => {
  //       alert(`Error on changing patient email, ${err}`);
  //       setLoading(false);
  //     });
  // }, [authToken, email, tempEmail]);

  const confirmNameChange = useCallback(async () => {
    const confirm = window.confirm(
      `Are you sure you want to change this patient's name to: \n${tempFullname}`
    );

    confirm && submitNameChange();
  }, [submitNameChange, tempFullname]);

  // const confirmEmailChange = useCallback(async () => {
  //   const confirm = window.confirm(
  //     `Are you sure you want to change this patient's email to: \n${tempEmail}`
  //   );

  //   confirm && submitEmailChange();
  // }, [submitEmailChange, tempEmail]);

  return (
    <Fragment>
      {data ? (
        <div className={"patientContainer"}>
          <div className={"patientData cascade"}>
            <span>
              Name:
              <input
                style={{ marginLeft: 4 }}
                type="text"
                value={tempFullname}
                onChange={(i) => {
                  setTempFullname(i.target.value);
                }}
              />
              <button
                className={"cta"}
                style={{ marginLeft: 4 }}
                onClick={() => {
                  confirmNameChange();
                }}
              >
                {"Save"}
              </button>
            </span>
            <span>{`ID: ${hubspotId}`}</span>
            <span>{`Email: ${email}`}</span>
            {/* uncomment below when the email change stuff is working
             <span>
              Email:
              <input
                style={{ marginLeft: 4, width: 300 }}
                type="text"
                value={tempEmail}
                onChange={(i) => {
                  setTempEmail(i.target.value);
                }}
              />
              <button
                className={"cta"}
                style={{ marginLeft: 4 }}
                onClick={() => {
                  confirmEmailChange();
                }}
              >
                {"Save"}
              </button>
            </span> */}
            {patientType === ProgrammeType.TDR && (
              <span>{`Trial patient: ${trialPatient}`}</span>
            )}
            {data.patientType !== ProgrammeType.MOMENTA && (
              <span>{`Programme: ${
                programmeStatus?.programmeId
                  ? getProgrammeName(programmeStatus.programmeId).name
                  : "unknown"
              }`}</span>
            )}
            {medication && <span>{`Latest medication: ${medication}mg`}</span>}
            <span>{`Status: ${status}`}</span>
            <span>{`Pending cancellation: ${
              pendingCancellation ? "Yes" : "No"
            }`}</span>
            <span>{`Current Week Number: ${weekNumber || -1}`}</span>
            {level?.currentLevelInt !== null &&
              !isNaN(Number(level?.currentLevelInt)) && (
                <span>{`Level: ${level.currentLevelInt}`}</span>
              )}
            {patientType === ProgrammeType.TDR && (
              <span>{`Is risk: ${isRisk ? "Yes" : "No"}`}</span>
            )}
            <span>{`Current Weight: ${Object.values(patientWeight).join(
              ""
            )}`}</span>
            <span>{`Starting Weight: ${Object.values(patientStartWeight).join(
              ""
            )}`}</span>
            {status === "FREE_TRIAL" && selectedStartDate && (
              <span>{`Due to start: ${format(
                parseISO(selectedStartDate),
                "dd/MM/yyyy"
              )}`}</span>
            )}
            {data?.hubspotId && (
              <span>
                {"Link to CIO entry: "}
                <a
                  href={`https://fly.customer.io/env/${
                    isStaging ? "113066" : "113271"
                  }/people/${data.hubspotId}`}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  {data.hubspotId}
                </a>
              </span>
            )}
          </div>
          {loading && (
            <div className="loadingOverlay">
              <div>Loading...</div>
            </div>
          )}
        </div>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};
