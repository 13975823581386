/**
 * @name getTimeOfDay
 * @description Checks the current time and evaluates what greeting should be shown to the user based on the time of day
 * 
 * @returns {string} getTimeOfDay
 */
const getTimeOfDay = () => {
  const now = new Date();
  const currentHours = now.getHours();

  return currentHours < 12
    ? "Good morning"
    : currentHours < 18
    ? "Good afternoon"
    : "Good evening";
};

export default getTimeOfDay;
