import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import logger from "../helpers/logger";
import promiseAccumulator from "../helpers/promiseAccumulator";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import { MealProductData } from "./create-patient-meal-order";
import { PatchOrderModal } from "./editMealOrderModal";
import { PushOrderModal } from "./pushOrderModal";

export const PatientMealOrders = ({
  data,
  productData,
  email,
  isLoading,
  refresh,
}: {
  data: string[] | undefined;
  email: string | null;
  isLoading: (input: boolean) => void;
  refresh: () => void;
  productData: MealProductData | undefined;
}) => {
  const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [orderId, setOrderId] = useState(-1);
  const [orderToEdit, setOrderToEdit] = useState(-1);

  useEffect(() => {
    if (orderId !== -1) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [orderId]);

  useEffect(() => {
    if (orderToEdit !== -1) {
      setIsEditModalVisible(true);
    } else {
      setIsEditModalVisible(false);
    }
  }, [orderToEdit]);

  const handleDeleteMealOrder = (id: number) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this meal order?"
    );

    const requests = [
      () =>
        axios({
          method: "post",
          url: `${apiEndpoint}admin/patient/${email}/mealOrders/delete`,
          data: { orderId: id },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];
    confirm &&
      promiseAccumulator(requests)
        .then(([response1]: any) => {
          logger.info("Delete meal order", response1);
          alert("Meal Order has been deleted");
          return [response1];
        })
        .then(([response1]) => {
          if (response1.statusText === "OK") {
            updatePatientNotes({
              user,
              authToken,
              data,
              note: "Patient meal order was deleted",
            });
          }
        })
        .then(() => {
          refresh();
        })
        .catch((error) => {
          logger.error(error);
        });
  };

  if (!email) {
    return null;
  }

  return (
    <div className={"patientNoteData"}>
      {data?.map((item: any, index) => {
        return (
          <div
            key={index}
            className={"noteItemContainer"}
            style={{ marginBottom: 30 }}
          >
            <div className={"noteAttribute"}>{`Status: ${item.status}`}</div>
            <div className={"noteAttribute"}>Order Id: {item.orderId}</div>
            <div className={"noteAttribute"}>Order Type: {item.orderType}</div>
            <div className={"noteAttribute"}>Order Date: {item.orderDate}</div>
            <div className={"noteAttribute"}>
              Estimated Arrival: {item.estimatedArrival}
            </div>
            <div className={"noteAttribute"}>
              Dispatch Date: {item.dispatchDate}
            </div>
            <div className={"noteAttribute"}>
              Payment Date: {item.paymentDate}
            </div>
            <div className={"noteAttribute"}>
              Overcharge Amount: £{item.overchargeAmount}
            </div>

            <div className={"note"}>{`Items:`}</div>
            <div>
              {Object.entries<{
                title: string;
                quantity: number;
              }>(item.items).map(([key, orderItem]: any, index: number) => {
                return (
                  <div key={index}>
                    {orderItem.quantity} x {orderItem.title}
                  </div>
                );
              })}
            </div>
            <div style={{ flex: 1, flexDirection: "row" }}>
              <button
                style={{ width: "100%", marginBottom: 8 }}
                className="cta cancelUserButton"
                onClick={() => {
                  handleDeleteMealOrder(item.databaseId);
                }}
              >
                {"Delete Order"}
              </button>
              <button
                style={{ width: "100%", marginBottom: 8 }}
                className="cta"
                onClick={() => {
                  setOrderToEdit(item);
                }}
              >
                {"Edit this order"}
              </button>
              {item.status === "ready" && (
                <button
                  style={{ width: "100%", marginBottom: 8 }}
                  className="cta"
                  onClick={() => {
                    setOrderId(item.databaseId);
                  }}
                >
                  {"Send to Shopify"}
                </button>
              )}
            </div>
          </div>
        );
      })}
      {isModalVisible && (
        <PushOrderModal
          dismissModal={() => {
            setOrderId(-1);
          }}
          email={email}
          orderId={orderId}
          data={data || null}
          refresh={refresh}
        />
      )}
      {isEditModalVisible && (
        <PatchOrderModal
          dismissModal={() => {
            setOrderToEdit(-1);
          }}
          email={email}
          existingOrder={orderToEdit}
          data={data || null}
          refresh={refresh}
          productData={productData}
        />
      )}
    </div>
  );
};
