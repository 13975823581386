import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import "./globals.css";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import logger from "../helpers/logger";
import promiseAccumulator from "../helpers/promiseAccumulator";

export const MagicLinkSender = ({
  data,
}: {
  data: { [key: string]: any } | null;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
  const handleSendMagicLink = (type: string = "email") => {
    const confirm = window.confirm(
      "Are you sure you want to resend the magic link?"
    );

    const requests = [
      () =>
        axios({
          method: "post",
          url: `${apiEndpoint}admin/${
            type === "phone" ? "send_magic_link_via_sms" : "send_magic_link"
          }/`,
          data: {
            email: data?.email,
            ...(type === "phone" ? { phone: data?.phone } : {}),
          },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    confirm &&
      promiseAccumulator(requests)
        .then(([response1]: any) => {
          logger.info("Magic Link Sent:", response1);
          alert("Magic link was sent!");
          return [response1];
        })
        .then(([response1]) => {
          if (response1.statusText === "OK") {
            updatePatientNotes({
              user,
              authToken,
              data,
              note: "Magic link was sent",
            });
          }
        })
        .catch((error) => {
          logger.error(error);
        });
  };
  return (
    <Fragment>
      {data && data?.email ? (
        <div>
          <button
            className={"cta magicLinkButton"}
            onClick={() => handleSendMagicLink()}
          >
            {"Send magic link via email"}
          </button>
          {data?.phone && (
            <button
              className={"cta magicLinkButton"}
              onClick={() => handleSendMagicLink("phone")}
            >
              {"Send magic link via phone"}
            </button>
          )}
        </div>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};
