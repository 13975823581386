import React, { useEffect, useMemo, useState } from "react";
import { isHabitualEmail, logout } from "../helpers/firebaseHelper";
import { useNavigate } from "react-router-dom";
import { auth } from "../helpers/firebaseHelper";
import { useAuthState } from "react-firebase-hooks/auth";
import "./globals.css";
import getTimeOfDay from "../helpers/getTimeOfDay";
import logo from "../images/h-icon.svg";
import momentaLogo from "../images/momenta-logo.svg";
export const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;

export const Sidebar = ({ activeTab }: { activeTab: string }) => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [isHabitualAdmin, setIsHabitualAdmin] = useState(false);

  useEffect(() => {
    if (user && user.email && isHabitualEmail(user.email)) {
      setIsHabitualAdmin(true);
    }
  }, [user]);

  const timeOfDay = useMemo(getTimeOfDay, []);

  if (!user || !user.email) {
    console.log('sidebar would be going home')
    // navigate("/");
    return null;
  } else {
    return (
      <div className={"sidebar"}>
        <h1 className={"sidebarHeader"}>HeyCeres</h1>
        <div>
          <button
            onClick={() => {
              navigate("/dashboard");
            }}
            className={`sidebarNavButton ${
              activeTab === "dashboard" && "activeNavButton"
            }`}
          >
            <span>Dashboard</span>
          </button>
          {isHabitualAdmin && (
            <>
              <button
                onClick={() => {
                  navigate("/prescriptionPayments");
                }}
                className={`sidebarNavButton ${
                  activeTab === "prescriptionPayments" && "activeNavButton"
                }`}
              >
                <span>Payments</span>
              </button>
            </>
          )}
          <button
            onClick={() => {
              navigate("/newMomentaPatient");
            }}
            className={`sidebarNavButton ${
              activeTab === "newMomentaPatient" && "activeNavButton"
            }`}
          >
            <span>Add new Momenta patient</span>
          </button>
        </div>
        <div className={"sidebarFooter"}>
          <div className={"sidebarFooterText"}>
            <h2 className={"cascade"}>
              <span>{timeOfDay}</span>
              <span>{user.displayName}</span>
            </h2>
            <img
              src={isHabitualAdmin ? logo : momentaLogo}
              alt=""
              style={{ maxHeight: "36px", maxWidth: "128px", width: "100%" }}
            />
          </div>
          <button
            className="cta login__btn login__google"
            onClick={() => {
              logout();
              navigate("/");
            }}
          >
            Sign out
          </button>
          <div style={{marginTop: 6, color: '#0f1a18', fontSize: 8, textAlign: 'center'}}>
            Updated: 2024/11/11 (1)
            </div>
        </div>
      </div>
    );
  }
};
