import merge from "deepmerge";

const getMergedStyles = (
  baseStyles: { [key: string]: any },
  overridingStyles: { [key: string]: any },
  variant: string | number | any[],
) => {
  const variantStyles = Array.isArray(variant)
    ? variant.reduce((acc, value) => {
        return merge.all([
          acc,
          Object((variant && baseStyles[value]) || {}),
          Object((variant && overridingStyles[value]) || {}),
        ]);
      }, {})
    : merge(
        Object((variant && baseStyles[variant]) || {}),
        Object((variant && overridingStyles[variant]) || {}),
      );
  return merge.all([
    Object(baseStyles),
    Object(overridingStyles),
    variantStyles,
  ]);
};

export default getMergedStyles;
