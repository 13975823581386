import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import { useCallback, useEffect, useState } from "react";
import { format, sub } from "date-fns";
import "./globals.css";
import logger from "../helpers/logger";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import promiseAccumulator from "../helpers/promiseAccumulator";

export const ChangeWeekNumber = ({
  data,
  refresh,
}: {
  data: {
    firstName: string;
    programmeStage: {
      startDate: string;
      week: number;
    };
    status: string;
    email: string;
    hubspotId: string;
  } | null;
  refresh: () => void;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const { firstName, programmeStage, email, hubspotId } = data || {};
  const { startDate, week: weekNumber } = programmeStage || {};
  const [currentStartDate, setCurrentStartDate] = useState<
    string | Date | undefined
  >(startDate);
  const [currentWeekNumber, setCurrentWeekNumber] = useState(weekNumber || -1);
  const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
  const handleChangeWeekNumber = () => {
    const weeks = currentWeekNumber - 1 || 0;

    if (!currentStartDate) {
      throw Error("There's no startDate that has been found!");
    }

    const newStartDate = format(
      sub(new Date(), {
        days: new Date().getDay() - 1,
        weeks,
      }),
      "yyy-MM-dd"
    );

    setCurrentStartDate(newStartDate);
    const requests = [
      () =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/v2/patient/`,
          data: {
            email: email,
            startDate: newStartDate,
            selectedStartDate: newStartDate,
            weekNumber: currentWeekNumber,
            hubspotId,
          },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    promiseAccumulator(requests)
      .then(([response1]: any) => {
        logger.info("Week Number Changed:", response1);
        return [response1];
      })
      .then(([response1]) => {
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: `Week number was changed from ${weekNumber} to ${currentWeekNumber}`,
          });
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        logger.error(error);
      });
  };
  return (
    <div>
      <label>{`${firstName}'s current programme week number is:`}</label>
      <input
        onChange={(e) => {
          if (e.target.value === "0") {
            e.target.value = "-1";
          }
          setCurrentWeekNumber(Number(e.target.value));
        }}
        type={"number"}
        defaultValue={currentWeekNumber}
      />
      <button className={"cta"} onClick={handleChangeWeekNumber}>
        {"SAVE!"}
      </button>
      <div>{`Programme start date: ${
        currentStartDate && format(new Date(currentStartDate), "dd-MM-yyyy")
      }`}</div>
    </div>
  );
};
