import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../helpers/firebaseHelper";
import { useAuthState } from "react-firebase-hooks/auth";
import "./globals.css";
import { Sidebar } from "./sidebar";
import axios from "axios";
import isEmailAddressValid from "../helpers/isEmailAddressValid";
export const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;

export const NewMomentaPatientForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);

  const [patientDetails, setPatientDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    selectedStartDate: "",
    sex: "unknown",
    uniqueIdentifier: "",
    bpTracking: "off",
  });

  const handleSubmitDetails = (type: string = "email") => {
    const confirm = window.confirm(
      `Please confirm the participant details: 
      First Name: ${patientDetails.firstName}
      Last Name: ${patientDetails.lastName}
      Unique Identifier: ${patientDetails.uniqueIdentifier}
      Sex: ${patientDetails.sex}
      Email address: ${patientDetails.email}
      Start date: ${patientDetails.selectedStartDate}
      Phone: ${patientDetails.phone}
      BP tracking on?: ${patientDetails.bpTracking}`
    );
    setLoading(true);
    confirm &&
      axios({
        method: "POST",
        url: `${apiEndpoint}admin/patient`,
        headers: {
          "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
          Authorization: `Bearer ${authToken}`,
          "x-api-key": `${process.env.REACT_APP_APP_API_KEY}`,
        },
        data: {
          ...patientDetails,
          momentaPatient: true,
        },
      })
        .then((response1: any) => {
          if (response1.data) {
            return response1.data;
          }
        })
        .then((data) => {
          setLoading(false);
          alert("Participant created successfully");
        })
        .catch((error) => {
          setLoading(false);
          alert(`Something went wrong ${error}`);
        });
  };

  if (!user || !user.email) {
    navigate("/");
    return null;
  } else {
    return (
      <div className={"dash"}>
        <Sidebar activeTab="newMomentaPatient" />
        <div className={"screen"}>
          {loading && (
            <div className="loadingOverlay">
              <div>Loading...</div>
            </div>
          )}
          <Fragment>
            <div className={"emailInput"}>
              <div className={"emailInputLabel cascade"}>
                <label>{"Please enter participant details:"}</label>
              </div>
            </div>
          </Fragment>
          {loading && (
            <div className="loadingOverlay">
              <div>Loading...</div>
            </div>
          )}
          <div
            style={{
              margin: 5,
              display: "flex",
              flexDirection: "column",
              maxWidth: "40%",
            }}
          >
            <div style={{ display: "grid" }}>
              <label style={{ flex: 1 }}>First Name</label>
              <input
                value={patientDetails.firstName}
                onChange={(e: { target: { value: any } }) => {
                  setPatientDetails({
                    ...patientDetails,
                    firstName: e.target.value,
                  });
                }}
                type={"text"}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label>Last Name</label>
              <input
                value={patientDetails.lastName}
                onChange={(e: { target: { value: any } }) => {
                  setPatientDetails({
                    ...patientDetails,
                    lastName: e.target.value,
                  });
                }}
                type={"text"}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label>Email</label>
              <input
                value={patientDetails.email}
                onChange={(e: { target: { value: string } }) => {
                  setPatientDetails({
                    ...patientDetails,
                    email: e.target.value.trim(),
                  });
                }}
                type={"email"}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label>Unique identifier</label>
              <input
                value={patientDetails.uniqueIdentifier}
                onChange={(e: { target: { value: any } }) => {
                  setPatientDetails({
                    ...patientDetails,
                    uniqueIdentifier: e.target.value,
                  });
                }}
                type={"text"}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label>Phone number</label>
              <input
                style={
                  patientDetails.phone.length >= 9 &&
                  patientDetails.phone.length < 11
                    ? { border: "1px red solid" }
                    : {}
                }
                placeholder="07777 777 777"
                value={patientDetails.phone}
                onChange={(e: { target: { value: string } }) =>
                  setPatientDetails({
                    ...patientDetails,
                    phone: e.target.value.trim(),
                  })
                }
                type={"tel"}
              />
            </div>
            <div style={{ display: "grid" }}>
              <label>Sex</label>
              <select
                onChange={(e) => {
                  setPatientDetails({
                    ...patientDetails,
                    sex: e.target.value,
                  });
                }}
              >
                <option
                  selected={patientDetails.sex === "female"}
                  value={"female"}
                >
                  Female
                </option>
                <option selected={patientDetails.sex === "male"} value={"male"}>
                  Male
                </option>
                <option
                  selected={patientDetails.sex === "unknown"}
                  value={"unknown"}
                >
                  Unknown
                </option>
              </select>
            </div>
            <div style={{ display: "grid" }}>
              <label>Blood pressure tracking?</label>
              <select
                onChange={(e) => {
                  setPatientDetails({
                    ...patientDetails,
                    bpTracking: e.target.value,
                  });
                }}
              >
                <option selected={patientDetails.sex === "off"} value={"off"}>
                  Off
                </option>
                <option selected={patientDetails.sex === "on"} value={"on"}>
                  On
                </option>
              </select>
            </div>
            <button
              onClick={() => {
                handleSubmitDetails();
              }}
              disabled={
                !isEmailAddressValid(patientDetails.email) ||
                !patientDetails.firstName ||
                !patientDetails.lastName ||
                patientDetails.phone.length < 11
              }
              className="cta"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
};
