/**
 * @name promiseMe
 * @description Executes a promise and resolves asap. Is utilised by the promiseAccumulator function to take advantage of the reduce() method's synchronous nature and pass back the result of a promise to promiseAccumulator's accumulator
 * @param {Promise} promise
 *
 * @returns {Promise} promiseMe
 */
const promiseMe = (promise: any) => {
  return new Promise(async (resolve, reject) => {
    await promise()
      .then((response: unknown) => resolve(response))
      .catch((err: any) => {
        if (err.message === "Request failed with status code 498") {
          alert("Your login token may have expired");
        } else {
          alert(JSON.stringify(err.response.data));
        }
        resolve(err);
      });
  });
};

/**
 * @name promiseAccumulator
 * @description Utilises the reduce() array method to sequentially and synchonously resolve a list of promises
 * @param {Function[]} items
 *
 * @returns {Promise} promiseAccumulator
 */
const promiseAccumulator = (items: Function[]) => {
  return new Promise((resolve, reject) => {
    items
      .reduce(async (accumulatorPromise: any, request) => {
        const promise = await promiseMe(request);
        // TODO: Haven't quite figured out how to get this not change the accumulator from an array into a promise, but will look into this later
        return await (Array.isArray(accumulatorPromise)
          ? [...accumulatorPromise, promise]
          : accumulatorPromise?.then?.((response: any) => [
              ...response,
              promise,
            ]));
      }, [])
      .then((results: any) => {
        resolve(results);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export default promiseAccumulator;
