import axios from "axios";
import { Fragment, useCallback, useEffect, useState } from "react";
import logger from "../helpers/logger";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import "./globals.css";
import promiseAccumulator from "../helpers/promiseAccumulator";

export const ResetToWelcomeGuide = ({
  data,
  refresh,
}: {
  data: {
    email: string;
    hubspotId: string;
    paymentDate: string;
  } | null;
  refresh: () => void;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const { email } = data || {};
  const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
  const handleResetPatient = (type: string = "email") => {
    const confirm = window.confirm(
      "Are you sure you want to reset this patient to Welcome Guide?"
    );

    const requests = [
      () =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/${email}/resetToWelcomeGuide/`,
          data: {},
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    confirm &&
      promiseAccumulator(requests)
        .then(([response1]: any) => {
          logger.info("Reset patient to welcome guide:", response1);
          alert("Patient has been reset to Welcome Guide!");
          return [response1];
        })
        .then(([response1]) => {
          if (response1.statusText === "OK") {
            updatePatientNotes({
              user,
              authToken,
              data,
              note: "Patient was reset to Welcome Guide",
            });
          }
        })
        .then(() => {
          refresh();
        })
        .catch((error) => {
          logger.error(error);
        });
  };

  return (
    <Fragment>
      <div
        style={{
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <label>{`Reset patient to Welcome Guide`}</label>
        <button
          style={{ width: "100%" }}
          className="cta cancelUserButton"
          onClick={() => handleResetPatient()}
        >
          {"Reset Patient"}
        </button>
      </div>
    </Fragment>
  );
};
