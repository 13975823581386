import log from "loglevel";
import getUrlParams from "./getUrlParams";

const env = process.env.REACT_APP_ENV;

const logger = log.noConflict();

const urlParams = getUrlParams();

const queryLogLevel =
  ["trace", "debug", "info", "warn", "error", "silent"].includes(
    urlParams.loglevel
  ) && urlParams.loglevel;

const level = queryLogLevel || (env !== "production" ? "trace" : "warn");

logger.setLevel(level);

export default logger;
