import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import "./globals.css";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import logger from "../helpers/logger";
import promiseAccumulator from "../helpers/promiseAccumulator";

export const ChangeEconsultStatus = ({
  data,
  refresh,
}: {
  data: {
    firstName: string;
    econsultStatus: string;
    email: string;
    hubspotId: string;
  } | null;
  refresh: () => void;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);

  const { firstName, econsultStatus, email, hubspotId } = data || {};
  const [currentStatus, setCurrentStatus] = useState(
    econsultStatus || "INCOMPLETE"
  );
  const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
  const handleChangeStatus = () => {
    const requests = [
      () =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/v2/patient/`,
          data: { email, econsultStatus: currentStatus, hubspotId },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    promiseAccumulator(requests)
      .then(([response1]: any) => {
        logger.info("econsult status Changed:", response1);
        return [response1];
      })
      .then((responses) => {
        if (responses[0].ok) {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: `Econsult Status was changed from ${econsultStatus} to ${currentStatus}`,
          });
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  return (
    <Fragment>
      {econsultStatus ? (
        <div className={"changeStatus cascade centre"}>
          <label>{`${firstName}'s current econsult status is:`}</label>
          <div>
            <select
              onChange={(e) => {
                setCurrentStatus(e.target.value);
              }}
              defaultValue={currentStatus}
            >
              <option value={"ELIGIBLE"}>{"ELIGIBLE"}</option>
              <option value={"INELIGIBLE"}>{"INELIGIBLE"}</option>
              <option value={"INCOMPLETE"}>{"INCOMPLETE"}</option>
            </select>
            <button className={"cta"} onClick={handleChangeStatus}>
              {"SAVE!"}
            </button>
          </div>
        </div>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};
