
import { useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "../../styles/ag-grid.css";
import "../../styles/ag-theme-habitual.css";
import "../globals.css";
import { OrderItem } from "../../types/types"

export const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;

export const AddonsGrid = ({
  addons,
  onEdit,
  isHistoric,
}: {
  addons: OrderItem[];
  onEdit: () => void;
  isHistoric: boolean
}) => {


  const defaultColDef = useMemo(
    () => ({
      sortable: true,
    }),
    []
  );

  const gridRef: any = useRef();



  const columnsToUse = isHistoric ? [{
    headerName: "Item",
    field: `longTitle`,
    width: 200,
    resizable: true,

  },
  {
    headerName: "Quantity",
    field: `quantity`,
    width: 100,
    resizable: true,
  },
  {
    headerName: "Status",
    field: `status`,
    width: 100,
    resizable: true,
  },
  {
    headerName: "Order complete?",
    field: `shipped`,
    width: 150,
    resizable: true,
  }] : [{
      headerName: "Item",
      field: `longTitle`,
      resizable: true,
      width: 250,
    },
    {
      headerName: "Quantity",
      field: `quantity`,
      resizable: true,
      editable: true,
      width: 100,
    },
    {
      headerName: "Status",
      field: `status`,
      width: 100,
      resizable: true,
    }];

  return (
    <div
      style={{
        height: '300px',
        width: '40vw',
        padding: 10,
      }}
    >
      <div
        className="ag-theme-alpine"
        style={{
          height: "100%",
          width: "100%",
          opacity: 100,
          overflow: "hidden",
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={addons} // Row Data for Rows
          columnDefs={columnsToUse} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          onCellClicked={() => {
            console.log("Clicked");
          }} // Optional - registering for Grid Event
          onCellEditingStopped={() => onEdit()}
        />
      </div>
    </div>
  );
};
