import axios from "axios";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import {
  entryProps,
  fieldProps,
  getWeightInputData,
  weightDataProps,
} from "../helpers/getWeightInputData";
import imperialToMetricWeight from "../helpers/imperialToMetricWeight";
import logger from "../helpers/logger";
import promiseAccumulator from "../helpers/promiseAccumulator";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import "./globals.css";

export const UpdateStartWeight = ({
  data,
  refresh,
}: {
  data: {
    firstName: string;
    startWeight: { measurement: number; unit: string }[];
    email: string;
    hubspotId: string;
  } | null;
  refresh: () => void;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const { firstName, startWeight, email, hubspotId } = data || {};
  const [showIndex, setShowIndex] = useState(1);
  const weightArrayToObject = Object.assign({}, ...(startWeight || []));
  const [defaultFieldValue, setDefaultFieldValues] = useState<weightDataProps>({
    unit: weightArrayToObject?.unit?.toLowerCase(),
    measurement: [weightArrayToObject?.measurement],
    isValid: [true],
    kg: weightArrayToObject?.measurement,
  });
  const [weight, setWeight] = useState<weightDataProps>(defaultFieldValue);

  const weightInputData = getWeightInputData({
    weight,
    setWeight: (data) => {
      setWeight(data);
    },
    setShowIndex: (data) => {
      setShowIndex(data);
    },
    setDefaultFieldValues: (data) => {
      setDefaultFieldValues(data);
    },
  });

  const injectDefaultValues = (data: entryProps[]) => {
    return data.map((item: entryProps) => {
      const fields = item.fields;
      return {
        ...item,
        fields: fields.map((field) => {
          const { label } = field;
          const target = defaultFieldValue[label as keyof weightDataProps];
          return {
            ...field,
            ...(Number.isFinite(target) ? { defaultValue: target } : {}),
          };
        }),
      };
    });
  };

  const processedWeightData = injectDefaultValues(weightInputData);

  const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
  const handleChangeWeight = () => {
    const currentStartWeight =
      weight.unit === "st"
        ? imperialToMetricWeight(weight.measurement || [0])[0]
        : weight?.measurement?.[0] || [0];
    const requests = [
      () =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/v2/patient/`,
          data: {
            email: email,
            hubspotId,
            startWeightKg: currentStartWeight,
          },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    promiseAccumulator(requests)
      .then(([response1]: any) => {
        logger.info("Starting Weight Updated:", response1);
        return [response1];
      })
      .then(([response1]) => {
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: `Starting weight was updated from ${Object.values(
              startWeight?.[0] || ["NOT SET"]
            )?.join("")} to ${currentStartWeight}Kg`,
          });
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  const canSubmit = weight.isValid.every((value) => !!value);

  return (
    <Fragment>
      {startWeight ? (
        <div className={"weightContainer cascade centre"}>
          <label>{`${firstName}'s starting weight is:`}</label>
          <div className={"weightEditor"}>
            {processedWeightData && (
              <Fragment>
                {processedWeightData.map((entry: entryProps, index: number) => {
                  const { fields } = entry;
                  return (
                    index === showIndex &&
                    fields.map((field: fieldProps, index) => {
                      return (
                        <Fragment key={index}>
                          <input
                            {...{ ...field }}
                            onChange={(e) =>
                              field.onChange && field.onChange(e)
                            }
                            name={field.label}
                          />
                          <label htmlFor={field.label}>{field.label}</label>
                        </Fragment>
                      );
                    })
                  );
                })}
                {
                  <div>
                    {processedWeightData.map(
                      (entry: entryProps, index: number) => {
                        const { button } = entry;
                        return (
                          <button
                            className={"toggleButton"}
                            key={index}
                            onClick={(e) => {
                              button.onClick && button.onClick(e);
                            }}
                            disabled={index === showIndex}
                          >
                            {button.label}
                          </button>
                        );
                      }
                    )}
                  </div>
                }
              </Fragment>
            )}
            <button
              className={"cta"}
              onClick={handleChangeWeight}
              disabled={!canSubmit}
            >
              {"SAVE!"}
            </button>
          </div>
        </div>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};
