import { format } from "date-fns";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Multiselect } from "react-widgets";
import { auth } from "../helpers/firebaseHelper";
import "./globals.css";
import "react-widgets/styles.css";
import patientNotesTags from "../data/patient-notes-tags";
import { updatePatientNotes } from "../helpers/updatePatientNotes";

export const PatientNotes = ({
  data,
  email,
  isLoading,
}: {
  data: string[] | undefined;
  email: string | null;
  isLoading: (input: boolean) => void;
}) => {
  const [newNote, setNewNote] = useState("");
  const [notes, setNotes] = useState(data);
  const [tags, setTags] = useState<string[]>([]);
  const [tagFilter, setTagFilter] = useState<string[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [user] = useAuthState(auth);
  const [listOfTags, setListOfTags] = useState(patientNotesTags);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);
  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  useEffect(() => {
    if (tagFilter.length) {
      const res = data?.filter((item: any) => {
        return item.tags.some((tag: string) => {
          return tagFilter.includes(tag);
        });
      });
      setNotes(res);
    } else {
      setNotes(data);
    }
  }, [data, tagFilter]);

  const addNewNote = useCallback(() => {
    setDisabled(true);
    isLoading(true);
    updatePatientNotes({
      user,
      authToken,
      data: { email },
      note: newNote,
      tags,
      autoNote: false,
    }).then(async (response: any) => {
      if (response.data.PatientNotes) {
        const sortedNotes = response.data.PatientNotes.sort(
          (a: { createdAt: Date }, b: { createdAt: Date }) =>
            a.createdAt > b.createdAt ? -1 : 1
        );
        setNotes(sortedNotes);
      }
      return;
    });
    isLoading(false);
    setDisabled(false);
  }, [email, isLoading, newNote, tags, user, authToken]);

  const handleCreate = (name: string) => {
    setTags([...tags, name]);
    setListOfTags((data) => [name, ...data]);
  };

  const handleCreateFilter = (name: string) => {
    setTagFilter([...tagFilter, name]);
  };

  const getUserListOfTags = useMemo(() => {
    const deleteIndex = listOfTags.findIndex((tag) => tag === "HeyCeres Note");
    const userListOfTags = [...listOfTags];
    userListOfTags.splice(deleteIndex, 1);
    return userListOfTags;
  }, [listOfTags]);

  return (
    <Fragment>
      <div>
        <div
          className={"patientNoteData"}
          style={{ maxHeight: 200, marginBottom: 10 }}
        >
          {notes?.map((item: any, index) => {
            return (
              <div key={index} className={"noteItemContainer"}>
                <div
                  className={"noteAttribute"}
                >{`Author: ${item.writtenBy}`}</div>
                <div className={"noteAttribute"}>
                  {`Date: ${format(new Date(item.createdAt), "dd-MM-yyyy")}`}
                </div>
                <div className={"noteAttribute"}>{`Time: ${format(
                  new Date(item.createdAt),
                  "HH:mm"
                )}`}</div>
                <div className={"noteAttribute"}>{`Tags: ${item.tags?.join(
                  ", "
                )}`}</div>
                <div className={"note"}>{`Note: ${item.note}`}</div>
              </div>
            );
          })}
        </div>
        <div>
          <div className={"notesFilter"}>
            <div>Notes:</div>
            <Multiselect
              placeholder="Filter Notes"
              defaultValue={["Patient Update"]}
              data={listOfTags}
              value={tagFilter}
              onCreate={handleCreateFilter}
              allowCreate="onFilter"
              onChange={(i) => {
                setTagFilter(i);
              }}
            />
          </div>
          <div className={"cascade"}>
            <div>Add new note:</div>
            <textarea
              style={{ height: 100 }}
              className="newNoteTextInput"
              onChange={(e) => setNewNote(e.target.value)}
            />

            <div className={"tagsTitleText"}>Tags:</div>

            <div className="tagContainer">
              <Multiselect
                defaultValue={["Patient Update"]}
                data={getUserListOfTags}
                value={tags}
                onCreate={handleCreate}
                allowCreate="onFilter"
                onChange={setTags}
              />
            </div>

            <button
              className="cta newNoteButton"
              disabled={disabled}
              onClick={() => {
                addNewNote();
              }}
            >
              {"Add note"}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
