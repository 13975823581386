import { Fragment, useState } from "react";
import "./globals.css";
import { isStaging } from "./prescription-payments";
import { DelayMealOrder } from "./dealy-meal-order";
import { PauseWholePlan } from "./pause-payments";
import { CancellationModal } from "./cancellation-modal";

export const PatientTdrSubscription = ({
  data,
  refresh,
}: {
  data: { [key: string]: any } | null;
  refresh: () => void;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <Fragment>
      {data && data?.email ? (
        <div className={"patientSubscription"}>
          <div className={"patientSubscriptionLinks cascade"}>
            {data?.shopifyId && (
              <span>
                {"Link to Shopify account: "}
                <a
                  href={`https://tryhabitual${
                    isStaging ? "-staging-shop" : ""
                  }.myshopify.com/admin/customers/${data.shopifyId}`}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  {data.shopifyId}
                </a>
              </span>
            )}
            {data?.programmeStatus?.currentPlan && (
              <span>{`Payment plan: ${data.programmeStatus.currentPlan}`}</span>
            )}
            {data?.programmeStatus?.paymentDate && (
              <span>{`Meals payment date in app db: ${data.programmeStatus.paymentDate}`}</span>
            )}
          </div>
          <DelayMealOrder data={data as any} refresh={refresh} />
          <PauseWholePlan data={data as any} refresh={refresh} />
          <button
            className="cta cancelUserButton"
            onClick={() => setIsModalVisible(true)}
            style={{ marginTop: 12 }}
          >
            {"Cancel User Subscription"}
          </button>
          {isModalVisible && (
            <CancellationModal
              dismissModal={() => {
                setIsModalVisible(false);
              }}
              data={data}
              refresh={refresh}
            />
          )}
        </div>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};
