import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import { useCallback, useEffect, useState } from "react";
import "./globals.css";
import logger from "../helpers/logger";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import promiseAccumulator from "../helpers/promiseAccumulator";

export const EditPatientCoins = ({
  data,
  refresh,
}: {
  data: {
    firstName: string;
    programmeStage: {
      startDate: string;
      week: number;
    };
    status: string;
    email: string;
    hubspotId: string;
    balance: { habicoins: number };
    trackingStreak: { currentStreak: number };
  } | null;
  refresh: () => void;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const { firstName, email, hubspotId, balance } = data || {};
  const habicoins = balance?.habicoins;
  const trackingStreak = data?.trackingStreak?.currentStreak;
  const [currentCoins, setCurrentCoins] = useState(habicoins || 0);
  const [currentStreak, setCurrentStreak] = useState(trackingStreak || 0);
  const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
  const handleCoinsChange = () => {
    if (currentCoins == null) {
      throw Error("There's no number of coins that has been found!");
    }
    const requests = [
      () =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/v2/patient/`,
          data: {
            email: email,
            habicoins: currentCoins,
            hubspotId,
          },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    promiseAccumulator(requests)
      .then(([response1]: any) => {
        logger.info("Number of coins Changed:", response1);
        return [response1];
      })
      .then(([response1]) => {
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: `Patient habicoins was changed from ${habicoins} to ${currentCoins}`,
          });
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        logger.error(error);
      });
  };
  const handleStreakChange = () => {
    if (currentCoins == null) {
      throw Error("There's no streak that has been found!");
    }
    const requests = [
      () =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/v2/patient/`,
          data: {
            email: email,
            trackingStreak: currentStreak,
            hubspotId,
          },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    promiseAccumulator(requests)
      .then(([response1]: any) => {
        logger.info("Streak Changed:", response1);
        return [response1];
      })
      .then(([response1]) => {
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: `Patient streak was changed from ${trackingStreak} to ${currentStreak}`,
          });
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        logger.error(error);
      });
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <label>{`${firstName}'s current number of coins is:`}</label>
        <div>
          <input
            onChange={(e) => {
              setCurrentCoins(Number(e.target.value));
            }}
            type={"number"}
            defaultValue={habicoins}
          />
          <button className={"cta"} onClick={handleCoinsChange}>
            {"SAVE!"}
          </button>
        </div>
      </div>
      <div>
        <label>{`${firstName}'s current streak:`}</label>
        <div>
          <input
            onChange={(e) => {
              setCurrentStreak(Number(e.target.value));
            }}
            type={"number"}
            defaultValue={trackingStreak}
          />
          <button className={"cta"} onClick={handleStreakChange}>
            {"SAVE!"}
          </button>
        </div>
      </div>
    </div>
  );
};
