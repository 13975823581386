import axios from "axios";
import { Fragment, useCallback, useEffect, useState } from "react";
import logger from "../helpers/logger";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import "./globals.css";
import promiseAccumulator from "../helpers/promiseAccumulator";
import { nextMondays } from "./change-start-date";

export const PauseWholePlan = ({
  data,
  refresh,
}: {
  data: {
    programmeStatus: { paymentPlan: string };
    email: string;
    hubspotId: string;
    status: string;
  } | null;
  refresh: () => void;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const { email } = data || {};
  const [currentResumeDate, setResumeDate] = useState("");
  const [options, setOptions] = useState<any>();
  const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;
  const handleChangeProgramme = () => {
    const requests = [
      () =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/patient/${email}/pause`,
          data: {
            resumeDate: currentResumeDate,
          },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    promiseAccumulator(requests)
      .then(([response1]: any) => {
        logger.info("Paused:", response1);
        return [response1];
      })
      .then(([response1]) => {
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: `Payment plan was paused until ${currentResumeDate}`,
          });
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  useEffect(() => {
    const newOptions = nextMondays(currentResumeDate, setResumeDate, 8);
    setOptions(newOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div>
        <label>{`Pause whole programme until: `}</label>
        <select
          onChange={(e) => {
            setResumeDate(e.target.value);
          }}>
          {options}
        </select>
        <button className={"cta"} onClick={handleChangeProgramme}>
          {"SAVE!"}
        </button>
      </div>
    </Fragment>
  );
};
