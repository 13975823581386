import axios from "axios";
import { Fragment, useCallback, useEffect, useState } from "react";
import logger from "../helpers/logger";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";
import { updatePatientNotes } from "../helpers/updatePatientNotes";
import "./globals.css";
import promiseAccumulator from "../helpers/promiseAccumulator";

export const ChangeGLPProduct = ({
  data,
  refresh,
}: {
  data: {
    programmeStatus: { currentProductSku: string };
    email: string;
    hubspotId: string;
    status: string;
  } | null;
  refresh: () => void;
}) => {
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);
  const { programmeStatus, email } = data || {};
  const [currentProductSku, setCurrentProductSku] = useState(
    programmeStatus?.currentProductSku
  );
  const [possibleSwitches, setPossibleSwitches] = useState<any[]>([]);

  const apiEndpoint = process.env.REACT_APP_API_FIREBASE_URL;

  useEffect(() => {
    const getPossibleSwitches = async () => {
      const response = await axios({
        method: "get",
        url: `${apiEndpoint}admin/patient/${email}/switch`,
        headers: {
          "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });
      console.log(response.data);
      if (response?.data?.switches) {
        setPossibleSwitches(response.data.switches);
      }
    };
    if (auth && email) {
      getPossibleSwitches();
    }
  }, [email, authToken, apiEndpoint]);

  const handleChangeProduct = () => {
    const requests = [
      () =>
        axios({
          method: "patch",
          url: `${apiEndpoint}admin/patient/${email}/switch`,
          data: {
            productSku: currentProductSku,
          },
          headers: {
            "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }),
    ];

    promiseAccumulator(requests)
      .then(([response1]: any) => {
        logger.info("Changed Prodcut:", response1);
        return [response1];
      })
      .then(([response1]) => {
        if (response1.statusText === "OK") {
          updatePatientNotes({
            user,
            authToken,
            data,
            note: `Product was updated from ${programmeStatus?.currentProductSku} to ${currentProductSku}`,
          });
        }
      })
      .then(() => {
        refresh();
      })
      .catch((error) => {
        alert("Patient was not updated");
        logger.error(error);
      });
  };

  // TODO add logic here to make disabled if we cant switch right now
  // based their MR and payment status
  const disabled = false;

  if (possibleSwitches.length === 0) {
    return <Fragment>Getting possible switches...</Fragment>;
  }

  return (
    <Fragment>
      <div>
        <span>
          <b>Current medication: </b> {programmeStatus?.currentProductSku}
        </span>
        <div>
          <label>{`Available switches: `}</label>
          <select
            onChange={(e) => {
              setCurrentProductSku(e.target.value);
            }}
            defaultValue={currentProductSku}>
            {possibleSwitches.map((s) => {
              return (
                <option key={s.medicationSku} value={s.medicationSku}>
                  {s.medicationName}
                </option>
              );
            })}
          </select>
          <button
            className={"cta"}
            onClick={handleChangeProduct}
            disabled={disabled}
            style={disabled ? { cursor: "not-allowed" } : {}}>
            {"Switch"}
          </button>
        </div>
      </div>
    </Fragment>
  );
};
