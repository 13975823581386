import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import logger from "./logger";
const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    if (
      user &&
      user.email &&
      (isHabitualEmail(user.email) || isMomentaEmail(user.email))
    ) {
      // OK to login
      logger.log("YES Habitual person");
    } else {
      // Reject login
      signOut(auth);
      throw new Error("Not a Habitual team member");
    }
  } catch (err: any) {
    console.error(err);
    alert(err?.message);
  }
};

export const isHabitualEmail = (email: string) => {
  return email.toLowerCase().includes("@tryhabitual.com");
};

export const isMomentaEmail = (email: string) => {
  const othersArray = [
    "philippa.young45@gmail.com",
    "paulmchadwick@gmail.com",
    "clare@csidedesign.co.uk",
  ];
  return (
    email.toLowerCase().includes("discovermomenta.com") ||
    email.toLowerCase().includes("momentanewcastle.com") ||
    othersArray.includes(email.toLowerCase())
  );
};

export const logout = () => {
  signOut(auth);
};
