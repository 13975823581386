/**
 * @name getUrlParams
 * @description Gets the current URL and then extracts any search parameters it finds into an object
 * 
 * @returns {Object} getUrlParams
 */
const getUrlParams =  () => {
 const search = window?.location?.search?.substring?.(1)
 return (
   search &&
   JSON.parse(
     '{"' +
       decodeURI(search)
         .replace(/"/g, '\\"')
         .replace(/&/g, '","')
         .replace(/=/g, '":"') +
       '"}'
   )
 )
}

export default getUrlParams