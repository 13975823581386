import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, signInWithGoogle } from "../helpers/firebaseHelper";
import { useAuthState } from "react-firebase-hooks/auth";
import "./login.css";
import logger from "../helpers/logger";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const Login = () => {
  const [user, loading] = useAuthState(auth);

  const navigate = useNavigate();
  const query = useQuery();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleResetPassword = useCallback(() => {
    const email = prompt("Please enter email");
    if (email) {
      sendPasswordResetEmail(auth, email)
        .then((result) => {
          window.confirm("Reset password email has been sent");
        })
        .catch((error) => {
          window.confirm("Reset password email has been sent");
        });
    }
  }, []);

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      logger.log("Got user", user);
      console.log('navigating to: ', `/dashboard${query && "?" + query}`)
      navigate(`/dashboard${query && "?" + query}`);
    }
  }, [user, query, loading, navigate]);
  return (
    <div className="login">
      <div className="login__container">
        <div style={{ textAlign: "left" }}>Email</div>
        <input
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          style={{ marginBottom: 10 }}
          type="email"
        />

        <div style={{ textAlign: "left" }}>Password</div>
        <input
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          style={{ marginBottom: 10 }}
          type="password"
        />
        <button
          className="cta login__btn login__google"
          onClick={() =>
            signInWithEmailAndPassword(auth, email, password)
              .then((userCredential) => {
                // Signed in
                console.log("Signed in");
                // ...
              })
              .catch((error) => {
                setErrorMessage(`Error: ${error.code}`);
                console.log("error", error, error.code);
              })
          }
        >
          Login
        </button>
        <button onClick={handleResetPassword} style={{ marginTop: 10 }}>
          <div>Forgot Password</div>
        </button>

        <div style={{ color: "red", display: "flex" }}>{errorMessage}</div>

        <div style={{ marginTop: 20 }}>or</div>
        <button
          className="cta login__btn login__google"
          onClick={signInWithGoogle}
        >
          Login with Google
        </button>
      </div>
    </div>
  );
};
