import Plus from "../images/plusServing.svg";
import Minus from "../images/minusServing.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { apiEndpoint } from "./manage-patients";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../helpers/firebaseHelper";

export interface MealProductData {
  orderScreenDetails: {
    numberOfCartons: number;
    minimumOrderQuantity: number;
    productCategories: {
      [key: string]: {
        title: string;
        products: { [key: string]: { barcodeNumber: string; stock: string } };
      };
    };
    price: {
      text: string;
      value: number;
      currency: string;
    };
  };
}

const BoxCounter = ({
  count,
  updateCount,
  disabled,
}: {
  count: number;
  updateCount: (arg: number) => void;
  disabled: boolean;
}) => {
  return (
    <div
      style={{
        flexDirection: "row",
        alignItems: "center",
        borderWidth: 1,
        display: "flex",
      }}
    >
      <button
        disabled={count <= 0 || disabled}
        onClick={() => {
          updateCount(-1);
        }}
        style={{
          borderRadius: 100,
          height: 24,
          width: 24,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          backgroundColor: "#6ce4bf",
        }}
      >
        <img src={Minus} alt="" />
      </button>
      <div
        style={{
          borderWidth: 1,
          width: 37,
          height: 37,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          marginRight: 10,
          marginLeft: 10,
          borderRadius: 3,
          backgroundColor: "white",
        }}
      >
        <div style={{}}>{count}</div>
      </div>
      <button
        disabled={disabled}
        onClick={() => {
          updateCount(1);
        }}
        style={{
          borderRadius: 100,
          height: 24,
          width: 24,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          backgroundColor: "#6ce4bf",
        }}
      >
        <img src={Plus} alt="" />
      </button>
    </div>
  );
};

export const ItemComponent = ({
  key,
  product,
  count,
  setCount,
  hideStock,
}: {
  key: any;
  product: any;
  count: number;
  setCount: (n: number) => void;
  hideStock?: boolean;
}) => {
  return (
    <div style={{ marginBottom: 10, display: "flex" }}>
      <div style={{ flex: 1 }}>
        <div>{product.title}</div>
        {!hideStock && (
          <div style={{ fontSize: 12 }}>Stock: {product.stock}</div>
        )}
      </div>
      <BoxCounter
        count={count}
        disabled={false}
        updateCount={(i) => {
          setCount(count + i);
        }}
      />
    </div>
  );
};

export const CreatePatientMealOrder = ({
  data,
  email,
  isLoading,
}: {
  data: MealProductData | undefined;
  email: string | null;
  isLoading: (input: boolean) => void;
}) => {
  const [mealOrder, setMealOrder] = useState<{
    [key: string]: { quantity: number };
  }>({});
  const [orderResponse, setOrderResponse] = useState<string | undefined>();
  const [orderQuantity, setOrderQuantity] = useState(0);
  const [user] = useAuthState(auth);
  const [authToken, setAuthToken] = useState<string | undefined>();
  const getAuthToken = useCallback(async () => {
    const token = await user?.getIdToken();
    setAuthToken(token);
  }, [user]);

  useEffect(() => {
    getAuthToken();
  }, [getAuthToken]);

  useEffect(() => {
    let quantity = 0;
    Object.values(mealOrder).map((item) => {
      return (quantity += item.quantity);
    });
    setOrderQuantity(quantity);
  }, [mealOrder]);

  const disableSubmit = useMemo(() => {
    return (
      data && orderQuantity < data?.orderScreenDetails.minimumOrderQuantity
    );
  }, [data, orderQuantity]);

  const submitOrder = useCallback(async () => {
    setOrderResponse(undefined);
    try {
      await axios({
        method: "post",
        url: `${apiEndpoint}admin/patient/${email}/mealOrder`,
        data: { order: { ...mealOrder } },
        headers: {
          "admin-key": `${process.env.REACT_APP_API_ADMIN_KEY}`,
          Authorization: `Bearer ${authToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          setOrderResponse(response.data.headerTitle);
        } else {
          setOrderResponse("Oops something went wrong");
        }
      });
    } catch (err: any) {
      setOrderResponse(`Oops something went wrong: ${err.message}`);
    }
  }, [email, mealOrder, authToken]);

  const confirmOrderToBeSubmitted = useCallback(async () => {
    if (data) {
      const confirm = window.confirm(
        `This is over the free cartons for the user, submitting this order will charge the customer ${(
          (orderQuantity - data.orderScreenDetails.numberOfCartons) *
          data.orderScreenDetails.price.value
        ).toLocaleString("en-GB", {
          style: "currency",
          currency: data?.orderScreenDetails?.price?.currency,
        })}, are you sure you want to submit?`
      );

      confirm && submitOrder();
    }
  }, [data, orderQuantity, submitOrder]);

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <div>Number of cartons: {data?.orderScreenDetails.numberOfCartons}</div>
        <div>
          Minimum order: {data?.orderScreenDetails.minimumOrderQuantity}
        </div>

        <div>
          {"Cost for additional boxes: "}
          {(data?.orderScreenDetails?.price?.value ?? 0).toLocaleString(
            "en-GB",
            {
              style: "currency",
              currency: data?.orderScreenDetails?.price?.currency,
            }
          )}
        </div>
      </div>

      {data?.orderScreenDetails &&
        Object.entries(data?.orderScreenDetails.productCategories).map(
          ([key, category]) => {
            return (
              <div style={{ marginBottom: 20 }}>
                <div style={{ fontSize: 24, marginBottom: 5 }}>{key}</div>
                <div>
                  {Object.entries(category.products).map(([key, product]) => {
                    return (
                      <ItemComponent
                        setCount={(n) => {
                          setMealOrder({
                            ...mealOrder,
                            [product.barcodeNumber]: { quantity: n },
                          });
                        }}
                        count={mealOrder[product.barcodeNumber]?.quantity ?? 0}
                        key={key}
                        product={product}
                      />
                    );
                  })}
                </div>
              </div>
            );
          }
        )}

      <button
        disabled={disableSubmit}
        style={{
          backgroundColor: "#3898ec",
          padding: 10,
          opacity: disableSubmit ? 0.5 : 1,
          color: "white",
          borderRadius: 25,
        }}
        onClick={() => {
          data && orderQuantity > data?.orderScreenDetails.numberOfCartons
            ? confirmOrderToBeSubmitted()
            : submitOrder();
        }}
      >
        Submit Order
      </button>
      {orderResponse && <div>{orderResponse}</div>}
    </div>
  );
};
