import React from "react";
import "./globals.css";
import { SearchList } from "./search-list";

export const PatientList = ({
  list,
  onClick,
}: {
  list: {
    patientsByEmail: any[];
    patientsById: any[];
    patientsByName: any[];
  } | null;
  onClick: (arg: string) => void;
}) => {
  const {
    patientsByEmail = [],
    patientsById = [],
    patientsByName = [],
  } = list || {};

  const allItems = [patientsByEmail, patientsById, patientsByName]?.flat()

  return (
    <div className={"patientList"}>
      <h3>{"I've located the following set of patients in my database:"}</h3>
      <span className={"subtext"}>{"Please select from below"}</span>
      {patientsByEmail.length ? (
        <SearchList
          title={"By Email:"}
          items={patientsByEmail.map((item) => ({
            content: [
              item.email,
              `${item.firstName} ${item.lastName}`,
              item.hubspotId,
            ],
            onClick: () => onClick(item.email),
          }))}
        />
      ) : null}
      {patientsById.length ? (
        <SearchList
          title={"By ID:"}
          items={patientsById.map((item) => ({
            content: [
              item.hubspotId,
              item.email,
              `${item.firstName} ${item.lastName}`,
            ],
            onClick: () => onClick(item.email),
          }))}
        />
      ) : null}
      {patientsByName.length ? (
        <SearchList
          title={"By Name:"}
          items={patientsByName.map((item) => ({
            content: [
              `${item.firstName} ${item.lastName}`,
              item.email,
              item.hubspotId,
            ],
            onClick: () => onClick(item.email),
          }))}
        />
      ) : null}
      {allItems.length === 0 && <span className={'patientListNoResultsText'}>{'No patients were found.'}</span>}
    </div>
  );
};
