export enum ProgrammeAnalyticsName {
  HALF_BOARD_TRIAL = "2/day welcome guide",
  FULL_BOARD_TRIAL = "4/day welcome guide",
  PREMUIM_TRIAL = "Premium welcome guide",
  HALF_BOARD = "2/day",
  FULL_BOARD = "4/day",
  PREMIUM = "Premium",
  LINDUS_TRIAL_TRIAL = "Lindus trial welcome guide",
  LINDUS_TRIAL = "Lindus trial",
  SELF_CATERING_TRIAL = "digital welcome guide",
  SELF_CATERING = "digital",
  OLD_FULL = "TDR (Egg)",
  OLD_TRIAL = "Welcome guide (Egg)",
  OG_FULL = "TDR (Pre-Egg)",
  OG_TRIAL = "Welcome guide (Pre-Egg)",
  GLP_OZEMPIC_TRIAL_PROGRAMME_ID = "GLP injectable welcome guide",
  GLP_OZEMPIC_PROGRAMME_ID = "GLP injectable",
  GLP_RYBELSUS_TRIAL_PROGRAME_ID = "GLP Rybelsus welcome guide",
  GLP_RYBELSUS_PROGRAMME_ID = "GLP Rybelsus",
}

export enum TrialProgrammeAnalyticsName {
  LINDUS_TRIAL_TRIAL = "Lindus trial welcome guide",
  LINDUS_TRIAL = "Lindus trial",
}

export enum GlpProgrammeAnalyticsName {
  GLP_OZEMPIC_TRIAL_PROGRAMME_ID = "GLP injectable welcome guide",
  GLP_OZEMPIC_PROGRAMME_ID = "GLP injectable",
  GLP_RYBELSUS_TRIAL_PROGRAME_ID = "GLP Rybelsus welcome guide",
  GLP_RYBELSUS_PROGRAMME_ID = "GLP Rybelsus",
}

export enum PaymentPlans {
  DIGITAL = "digital only",
  TWO_D = "2/day",
  FOUR_D = "4/day",
  PREMIUM = "Premium",
}

// 1st programmes
const FREE_TRIAL_PROGRAMME_CMS_ID = "cksrpeqhsufy90c1401p1q1c3";
const FULL_PROGRAMME_CMS_ID = "ckrokdn3k63tz0c52v1b6iybi";
// egg programmes
const TRIAL_PACK_PROGRAMME_CMS_ID = "ckzr2bdzc8af00c11gvbym4mo";
const NEW_FULL_PROGRAMME_CMS_ID = "ckzr3hi6o9tay0f462b529h6f";
// cake programmes
const SELF_CATERING_PROGRAMME_CMS_ID = "cl2ka1rbk8dgv0bul3abop64j";
const SELF_CATERING_ACTIVE_PROGRAMME_CMS_ID = "cl2ka4lco8l890btfy4mlytj2";
const HALF_BOARD_PROGRAMME_CMS_ID = "cl2ka2oy096040burieydbfea";
const HALF_BOARD_ACTIVE_PROGRAMME_CMS_ID = "cl2ka4vgl9aey0burskg06yi3";
const FULL_BOARD_PROGRAMME_CMS_ID = "cl2ka2zx38ha60bul43tr5i1e";
const FULL_BOARD_ACTIVE_PROGRAMME_CMS_ID = "cl2ka54ul99qu0ctftciv2xtr";
const PREMIUM_PROGRAMME_CMS_ID = "cl9cnj7f42mwb0auln7cevabn";
const PREMIUM_ACTIVE_PROGRAMME_CMS_ID = "cl9co5cd335p10atd0kldzb6c";
// trial programmes
const LINDUS_TRIAL_PROGRAMME_CMS_ID = "cl9r22yjv2gfa0aufcwaibemt";
const LINDUS_TRIAL_ACTIVE_PROGRAMME_CMS_ID = "cl9r29anl2z2t0auoah364gid";
// glp programmes
const GLP_OZEMPIC_PROGRAMME_ID = "cleegl3sar7ot0ata886vr77x";
const GLP_OZEMPIC_TRIAL_PROGRAMME_ID = "cleegg9jute3g0bunklnbtnuh";
const GLP_RYBELSUS_PROGRAMME_ID = "cleegy52ytmfm0bt6gfgrddi1";
const GLP_RYBELSUS_TRIAL_PROGRAME_ID = "cleegx94krnih0atazfvu3prs";

export const getProgrammeName = (programmeId: string) => {
  switch (programmeId) {
    case SELF_CATERING_PROGRAMME_CMS_ID:
      return {
        name: ProgrammeAnalyticsName.SELF_CATERING_TRIAL,
      };
    case SELF_CATERING_ACTIVE_PROGRAMME_CMS_ID:
      return {
        name: ProgrammeAnalyticsName.SELF_CATERING,
      };
    case HALF_BOARD_PROGRAMME_CMS_ID:
      return {
        name: ProgrammeAnalyticsName.HALF_BOARD_TRIAL,
      };
    case HALF_BOARD_ACTIVE_PROGRAMME_CMS_ID:
      return {
        name: ProgrammeAnalyticsName.HALF_BOARD,
      };
    case FULL_BOARD_PROGRAMME_CMS_ID:
      return {
        name: ProgrammeAnalyticsName.FULL_BOARD_TRIAL,
      };
    case FULL_BOARD_ACTIVE_PROGRAMME_CMS_ID:
      return {
        name: ProgrammeAnalyticsName.FULL_BOARD,
      };
    case NEW_FULL_PROGRAMME_CMS_ID:
      return {
        name: ProgrammeAnalyticsName.OLD_FULL,
      };
    case TRIAL_PACK_PROGRAMME_CMS_ID:
      return {
        name: ProgrammeAnalyticsName.OLD_TRIAL,
      };
    case FULL_PROGRAMME_CMS_ID:
      return {
        name: ProgrammeAnalyticsName.OG_FULL,
      };
    case FREE_TRIAL_PROGRAMME_CMS_ID:
      return {
        name: ProgrammeAnalyticsName.OG_TRIAL,
      };
    case PREMIUM_PROGRAMME_CMS_ID:
      return {
        name: ProgrammeAnalyticsName.PREMUIM_TRIAL,
      };
    case PREMIUM_ACTIVE_PROGRAMME_CMS_ID:
      return {
        name: ProgrammeAnalyticsName.PREMIUM,
      };
    case LINDUS_TRIAL_PROGRAMME_CMS_ID:
      return {
        name: ProgrammeAnalyticsName.LINDUS_TRIAL_TRIAL,
      };
    case LINDUS_TRIAL_ACTIVE_PROGRAMME_CMS_ID:
      return {
        name: ProgrammeAnalyticsName.LINDUS_TRIAL,
      };
    case GLP_OZEMPIC_PROGRAMME_ID:
      return {
        name: ProgrammeAnalyticsName.GLP_OZEMPIC_PROGRAMME_ID,
      };
    case GLP_OZEMPIC_TRIAL_PROGRAMME_ID:
      return {
        name: ProgrammeAnalyticsName.GLP_OZEMPIC_TRIAL_PROGRAMME_ID,
      };
    case GLP_RYBELSUS_PROGRAMME_ID:
      return {
        name: ProgrammeAnalyticsName.GLP_RYBELSUS_PROGRAMME_ID,
      };
    case GLP_RYBELSUS_TRIAL_PROGRAME_ID:
      return {
        name: ProgrammeAnalyticsName.GLP_RYBELSUS_TRIAL_PROGRAME_ID,
      };
    default:
      return {
        name: "unknown",
      };
  }
};

export const getProgrammeCmsIdFromName = (name: string) => {
  switch (name) {
    case ProgrammeAnalyticsName.SELF_CATERING_TRIAL:
      return SELF_CATERING_PROGRAMME_CMS_ID;
    case ProgrammeAnalyticsName.SELF_CATERING:
      return SELF_CATERING_ACTIVE_PROGRAMME_CMS_ID;
    case ProgrammeAnalyticsName.HALF_BOARD_TRIAL:
      return HALF_BOARD_PROGRAMME_CMS_ID;
    case ProgrammeAnalyticsName.HALF_BOARD:
      return HALF_BOARD_ACTIVE_PROGRAMME_CMS_ID;
    case ProgrammeAnalyticsName.FULL_BOARD_TRIAL:
      return FULL_BOARD_PROGRAMME_CMS_ID;
    case ProgrammeAnalyticsName.FULL_BOARD:
      return FULL_BOARD_ACTIVE_PROGRAMME_CMS_ID;
    case ProgrammeAnalyticsName.OLD_FULL:
      return NEW_FULL_PROGRAMME_CMS_ID;
    case ProgrammeAnalyticsName.OLD_TRIAL:
      return TRIAL_PACK_PROGRAMME_CMS_ID;
    case ProgrammeAnalyticsName.OG_FULL:
      return FULL_PROGRAMME_CMS_ID;
    case ProgrammeAnalyticsName.OG_TRIAL:
      return FREE_TRIAL_PROGRAMME_CMS_ID;
    case ProgrammeAnalyticsName.PREMUIM_TRIAL:
      return PREMIUM_PROGRAMME_CMS_ID;
    case ProgrammeAnalyticsName.PREMIUM:
      return PREMIUM_ACTIVE_PROGRAMME_CMS_ID;
    case ProgrammeAnalyticsName.LINDUS_TRIAL_TRIAL:
      return LINDUS_TRIAL_PROGRAMME_CMS_ID;
    case ProgrammeAnalyticsName.LINDUS_TRIAL:
      return LINDUS_TRIAL_ACTIVE_PROGRAMME_CMS_ID;
    case ProgrammeAnalyticsName.GLP_OZEMPIC_PROGRAMME_ID:
      return GLP_OZEMPIC_PROGRAMME_ID;
    case ProgrammeAnalyticsName.GLP_OZEMPIC_TRIAL_PROGRAMME_ID:
      return GLP_OZEMPIC_TRIAL_PROGRAMME_ID;
    case ProgrammeAnalyticsName.GLP_RYBELSUS_PROGRAMME_ID:
      return GLP_RYBELSUS_PROGRAMME_ID;
    case ProgrammeAnalyticsName.GLP_RYBELSUS_TRIAL_PROGRAME_ID:
      return GLP_RYBELSUS_TRIAL_PROGRAME_ID;
  }
};

export enum ProgrammeType {
  TDR = "TDR",
  GLP = "GLP",
  MOMENTA = "MOMENTA",
}
